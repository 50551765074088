.audioIcon-module_equalizer__d0jfU {
  transform: rotate(180deg);
  display: inline-flex;
  width: 27px;
  height: 14px;
  align-items: baseline;
}
.audioIcon-module_equalizer__d0jfU svg {
  height: 16px;
  width: 2px;
  margin-left: 2px;
  transform: rotate(180deg);
  transform-origin: top;
  animation: audioIcon-module_equalize__cOheT 3.25s steps(25, end) 0s infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.audioIcon-module_equalizer__d0jfU svg:nth-child(1) {
  animation-duration: 1.9s;
  right: 4px;
}
.audioIcon-module_equalizer__d0jfU svg:nth-child(2) {
  animation-duration: 2.6s;
  right: 8px;
}
.audioIcon-module_equalizer__d0jfU svg:nth-child(3) {
  animation-duration: 3.3s;
  right: 12px;
}
.audioIcon-module_equalizer__d0jfU svg:nth-child(4) {
  animation-duration: 1.4s;
  right: 17px;
}
.audioIcon-module_equalizer__d0jfU svg:nth-child(5) {
  animation-duration: 2.1s;
  right: 21px;
}

@keyframes audioIcon-module_equalize__cOheT {
  0% {
    transform: scaleY(0.5);
  }
  4% {
    transform: scaleY(0.63);
  }
  8% {
    transform: scaleY(0.5);
  }
  12% {
    transform: scaleY(0.375);
  }
  16% {
    transform: scaleY(0.25);
  }
  20% {
    transform: scaleY(0.125);
  }
  24% {
    transform: scaleY(0.25);
  }
  28% {
    transform: scaleY(0.375);
  }
  32% {
    transform: scaleY(0.5);
  }
  36% {
    transform: scaleY(0.375);
  }
  40% {
    transform: scaleY(0.25);
  }
  44% {
    transform: scaleY(0.125);
  }
  48% {
    transform: scaleY(0.25);
  }
  52% {
    transform: scaleY(0.375);
  }
  56% {
    transform: scaleY(0.5);
  }
  60% {
    transform: scaleY(0.63);
  }
  64% {
    transform: scaleY(0.75);
  }
  68% {
    transform: scaleY(0.875);
  }
  72% {
    transform: scaleY(0.875);
  }
  76% {
    transform: scaleY(0.75);
  }
  80% {
    transform: scaleY(0.5);
  }
  84% {
    transform: scaleY(0.375);
  }
  88% {
    transform: scaleY(0.25);
  }
  92% {
    transform: scaleY(0.375);
  }
  96% {
    transform: scaleY(0.25);
  }
  100% {
    transform: scaleY(0.5);
  }
}
.album-module_album__JRkKf {
  display: flex;
  position: relative;
  cursor: inherit;
  width: 100%;
  height: 100%;
}
.album-module_album__JRkKf .album-module_albumInfo__bLN55 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 8px 0 8px;
  color: #fff;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}
.album-module_album__JRkKf .album-module_albumInfo__bLN55 .album-module_audioIcon__alWRM {
  fill: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.album-module_album__JRkKf .album-module_albumInfo__bLN55 p:first-of-type {
  font-weight: 700;
}
.album-module_album__JRkKf .album-module_albumInfo__bLN55 p {
  text-align: center;
  font-size: 0.75rem;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.album-module_album__JRkKf .album-module_albumImage__--JyR {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 136px;
}

.album-module_albumHover__X0Nta:hover .album-module_albumInfo__bLN55 {
  background-color: rgba(0, 0, 0, 0.8);
}
.album-module_albumHover__X0Nta:hover .album-module_albumInfo__bLN55 p,
.album-module_albumHover__X0Nta:hover .album-module_albumInfo__bLN55 .album-module_audioIcon__alWRM {
  opacity: 1;
}
.affiliatelinklist-module_linkList__vI7v- {
  list-style-type: none;
}
.affiliatelinklist-module_linkList__vI7v-:not(.affiliatelinklist-module_row__YZFm3) {
  margin: 0;
  padding: 0;
}
.affiliatelinklist-module_linkList__vI7v- li {
  display: inline;
  line-height: 1.57;
}
/**
 * button mixins
*/
/*
 * mixins for button styles
*/
/**
  * Style for <button>, including
  * CTA <button>
*/
.button-module_button__xhIDs {
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 32px;
  min-height: none;
  line-height: 1.5rem;
  padding: 1rem 1.5rem;
  position: relative;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  white-space: normal;
  border: none;
  border-radius: 40px;
  background: var(--secondary-atlas-web-blue);
  color: var(--white);
}
.w2 .button-module_button__xhIDs {
  text-transform: none;
  --secondary-atlas-web-blue: var(--base-contrast-accent, #0000eb);
}
.button-module_button__xhIDs::before {
  background: transparent;
  border: 1px solid var(--secondary-atlas-web-blue);
  border-radius: 40px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  opacity: 1;
  width: 100%;
  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;
}
.button-module_button__xhIDs:hover {
  background: transparent;
  text-decoration: none;
}
.button-module_button__xhIDs:hover::before {
  opacity: 1;
}
@media (min-width: 768px) {
  .button-module_button__xhIDs {
    max-width: 296px;
  }
}
.button-module_button__xhIDs:hover, .button-module_button__xhIDs:focus, .button-module_button__xhIDs:active {
  color: var(--secondary-atlas-web-blue);
  background: transparent;
}
.button-module_button__xhIDs::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_button__xhIDs.button-module_primary__UORM1 {
  background: var(--secondary-atlas-web-blue);
  color: var(--white);
}
.button-module_button__xhIDs.button-module_primary__UORM1:hover, .button-module_button__xhIDs.button-module_primary__UORM1:focus, .button-module_button__xhIDs.button-module_primary__UORM1:active {
  color: var(--secondary-atlas-web-blue);
  background: transparent;
}
.button-module_button__xhIDs.button-module_primary__UORM1::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_button__xhIDs.button-module_secondary__L6qHe {
  background: transparent;
  color: var(--secondary-atlas-web-blue);
}
.button-module_button__xhIDs.button-module_secondary__L6qHe:hover, .button-module_button__xhIDs.button-module_secondary__L6qHe:focus, .button-module_button__xhIDs.button-module_secondary__L6qHe:active {
  color: var(--white);
  background: var(--secondary-atlas-web-blue);
}
.button-module_button__xhIDs.button-module_secondary__L6qHe::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_button__xhIDs.button-module_custom__Ts2rF {
  background: var(--button-primary-surface, #0000eb);
  color: var(--button-primary-contrast, #fff);
}
.button-module_button__xhIDs.button-module_custom__Ts2rF:hover, .button-module_button__xhIDs.button-module_custom__Ts2rF:focus, .button-module_button__xhIDs.button-module_custom__Ts2rF:active {
  color: var(--button-primary-contrast-hover, #0000eb);
  background: transparent;
}
.button-module_button__xhIDs.button-module_custom__Ts2rF::before {
  border-color: var(--button-primary-border, #0000eb);
}
.button-module_button__xhIDs.button-module_dark__Zq7JH {
  background: var(--white);
  color: var(--blue);
}
.button-module_button__xhIDs.button-module_dark__Zq7JH:hover, .button-module_button__xhIDs.button-module_dark__Zq7JH:focus, .button-module_button__xhIDs.button-module_dark__Zq7JH:active {
  color: var(--white);
  background: transparent;
}
.button-module_button__xhIDs.button-module_dark__Zq7JH::before {
  border-color: #fff;
}
.button-module_button__xhIDs.button-module_disabled__LIz4h {
  background: var(--secondary-medium-gray);
  color: var(--white);
  pointer-events: none;
}
.button-module_button__xhIDs.button-module_disabled__LIz4h::before {
  opacity: 0;
}
.button-module_button__xhIDs.button-module_full-width__YpBVr {
  max-width: none;
}
@media (min-width: 768px) {
  .button-module_button__xhIDs.button-module_full-width__YpBVr {
    max-width: none;
  }
}
.button-module_button__xhIDs.button-module_short__NgXBC {
  padding: 13px 24px;
  min-height: 44px;
  line-height: 1.125rem;
  font-size: 0.75rem;
}
.button-module_button__xhIDs.button-module_text__TypHQ {
  margin: 0;
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 0px;
  background: none;
  border: none;
  color: var(--black);
  text-transform: none;
  letter-spacing: unset;
}
.button-module_button__xhIDs.button-module_text__TypHQ::before {
  display: none;
}
.button-module_button__xhIDs.button-module_text-button__w3lVN {
  background: transparent;
  color: var(--blue);
  height: -moz-fit-content;
  height: fit-content;
  min-height: 0px;
  margin-top: 0;
}
.button-module_button__xhIDs.button-module_text-button__w3lVN:hover, .button-module_button__xhIDs.button-module_text-button__w3lVN:focus, .button-module_button__xhIDs.button-module_text-button__w3lVN:active {
  color: var(--blue);
  background: transparent;
}
.button-module_button__xhIDs.button-module_text-button__w3lVN::before {
  border-color: transparent;
}
.button-module_button__xhIDs.button-module_narrow__9q0mx {
  line-height: 1.25;
  margin-top: 0;
  min-height: 44px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
  min-width: 80px;
}
@media (min-width: 768px) {
  .button-module_button__xhIDs.button-module_narrow__9q0mx {
    font-size: 1rem;
    min-width: 120px;
  }
}
.button-module_button__xhIDs.button-module_paginationStandard__KKk6S {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  margin: 0px;
}
.button-module_button__xhIDs.button-module_paginationStandard__KKk6S::before {
  border: none;
}
.button-module_button__xhIDs.button-module_paginationArrow__UIk53 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px;
  padding: 8px;
  border: 1px solid #607380;
  background-color: #fff;
}
.button-module_button__xhIDs.button-module_paginationArrow__UIk53::before {
  border: none;
}

.button-module_links-as-button__q5ged a {
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 32px;
  min-height: none;
  line-height: 1.5rem;
  padding: 1rem 1.5rem;
  position: relative;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  white-space: normal;
  border: none;
  border-radius: 40px;
  background: var(--secondary-atlas-web-blue);
  color: var(--white);
}
.w2 .button-module_links-as-button__q5ged a {
  text-transform: none;
  --secondary-atlas-web-blue: var(--base-contrast-accent, #0000eb);
}
.button-module_links-as-button__q5ged a::before {
  background: transparent;
  border: 1px solid var(--secondary-atlas-web-blue);
  border-radius: 40px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  opacity: 1;
  width: 100%;
  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;
}
.button-module_links-as-button__q5ged a:hover {
  background: transparent;
  text-decoration: none;
}
.button-module_links-as-button__q5ged a:hover::before {
  opacity: 1;
}
@media (min-width: 768px) {
  .button-module_links-as-button__q5ged a {
    max-width: 296px;
  }
}
.button-module_links-as-button__q5ged a:hover, .button-module_links-as-button__q5ged a:focus, .button-module_links-as-button__q5ged a:active {
  color: var(--secondary-atlas-web-blue);
  background: transparent;
}
.button-module_links-as-button__q5ged a::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_links-as-button__q5ged.button-module_primary__UORM1 a {
  background: var(--secondary-atlas-web-blue);
  color: var(--white);
}
.button-module_links-as-button__q5ged.button-module_primary__UORM1 a:hover, .button-module_links-as-button__q5ged.button-module_primary__UORM1 a:focus, .button-module_links-as-button__q5ged.button-module_primary__UORM1 a:active {
  color: var(--secondary-atlas-web-blue);
  background: transparent;
}
.button-module_links-as-button__q5ged.button-module_primary__UORM1 a::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_links-as-button__q5ged.button-module_secondary__L6qHe a {
  background: transparent;
  color: var(--secondary-atlas-web-blue);
}
.button-module_links-as-button__q5ged.button-module_secondary__L6qHe a:hover, .button-module_links-as-button__q5ged.button-module_secondary__L6qHe a:focus, .button-module_links-as-button__q5ged.button-module_secondary__L6qHe a:active {
  color: var(--white);
  background: var(--secondary-atlas-web-blue);
}
.button-module_links-as-button__q5ged.button-module_secondary__L6qHe a::before {
  border-color: var(--secondary-atlas-web-blue);
}
.button-module_links-as-button__q5ged.button-module_dark__Zq7JH a {
  background: var(--white);
  color: var(--blue);
}
.button-module_links-as-button__q5ged.button-module_dark__Zq7JH a:hover, .button-module_links-as-button__q5ged.button-module_dark__Zq7JH a:focus, .button-module_links-as-button__q5ged.button-module_dark__Zq7JH a:active {
  color: var(--white);
  background: transparent;
}
.button-module_links-as-button__q5ged.button-module_dark__Zq7JH a::before {
  border-color: #fff;
}
.button-module_links-as-button__q5ged.button-module_disabled__LIz4h a {
  background: var(--secondary-medium-gray);
  color: var(--white);
  pointer-events: none;
}
.button-module_links-as-button__q5ged.button-module_disabled__LIz4h a::before {
  opacity: 0;
}
.button-module_links-as-button__q5ged.button-module_full-width__YpBVr a {
  max-width: none;
}
@media (min-width: 768px) {
  .button-module_links-as-button__q5ged.button-module_full-width__YpBVr a {
    max-width: none;
  }
}
.button-module_links-as-button__q5ged.button-module_short__NgXBC a {
  padding: 13px 24px;
  min-height: 44px;
  line-height: 1.125rem;
  font-size: 0.75rem;
}
.button-module_links-as-button__q5ged.button-module_narrow__9q0mx a {
  line-height: 1.25;
  margin-top: 0;
  min-height: 44px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
  min-width: 80px;
}
@media (min-width: 768px) {
  .button-module_links-as-button__q5ged.button-module_narrow__9q0mx a {
    font-size: 1rem;
    min-width: 120px;
  }
}

.button-module_darkSection__z7-Oh {
  background-color: #000;
}
.breadcrumbs-module_breadcrumbs__7GxHl ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 26px 0;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .breadcrumbs-module_breadcrumbs__7GxHl ul {
    margin: 0 0 52px;
  }
}
.breadcrumbs-module_breadcrumbs__7GxHl ul li {
  display: inline-block;
}
.breadcrumbs-module_breadcrumbs__7GxHl ul li:not(:last-child):after {
  content: "/";
  display: inline-block;
  margin: 0 8px;
  font-size: 0.75rem;
}
.w2 .breadcrumbs-module_breadcrumbs__7GxHl ul li:not(:last-child):after {
  content: ">";
}
.breadcrumbs-module_breadcrumbs__7GxHl ul li a::before {
  background-color: inherit;
}
.breadcrumbs-module_breadcrumbs__7GxHl ul a,
.breadcrumbs-module_breadcrumbs__7GxHl ul li {
  font-size: 0.75rem;
  line-height: 14px;
  display: inline-block;
  color: inherit;
}
.w2 .breadcrumbs-module_breadcrumbs__7GxHl ul a,
.w2 .breadcrumbs-module_breadcrumbs__7GxHl ul li {
  text-transform: none;
  color: var(--web2BreadcrumbOverride, inherit);
}
.breadcrumbs-module_breadcrumbs__7GxHl ul a {
  padding-bottom: 8px;
}
.cta-module_ctaText__VS85K {
  text-decoration: none;
  color: #0f63ff;
}

.cta-module_darkSection__jEiC6 {
  background-color: #000;
}
.rule-module_default__gu7Et {
  border: 0;
  border-bottom: 1px solid #d4d4d4;
}

.rule-module_basic__zB-C0 {
  border: 0;
  border-bottom: 1px solid var(--border-color);
}
@charset "UTF-8";
.dotseparator-module_dotSeparator__LaKMK::after {
  content: "·";
  font-size: 1rem;
  color: inherit;
}
.shadowBox-module_shadowBox__tz5FG {
  display: flex;
  box-shadow: 0 2px 15px -4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
}
.w2 .shadowBox-module_shadowBox__tz5FG {
  border-radius: 24px;
}
.shadowBox-module_shadowBox__tz5FG::after {
  content: "";
  background-color: #fff;
  box-shadow: 0 2px 15px -4px rgba(0, 0, 0, 0.15);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: box-shadow 0.25s ease, height 0.25s ease, width 0.25s ease;
  z-index: -1;
}
.w2 .shadowBox-module_shadowBox__tz5FG::after {
  border-radius: 24px;
}
@media (max-width: 767px) {
  .shadowBox-module_shadowBox__tz5FG.shadowBox-module_animateOnHoverMobile__mu7X1:hover::after {
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
    width: calc(100% + 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 768px) {
  .shadowBox-module_shadowBox__tz5FG.shadowBox-module_animateOnHoverDesktop__orSv9:hover::after {
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
    width: calc(100% + 16px);
    height: calc(100% + 16px);
  }
}
.styledContainer-module_styledContainer__8gqrW {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.styledContainer-module_styledContainer__8gqrW.styledContainer-module_navPadding__Sg-i1 {
  padding-top: 56px;
}
@media (min-width: 1115px) {
  .styledContainer-module_styledContainer__8gqrW.styledContainer-module_navPadding__Sg-i1 {
    padding-top: 72px;
  }
}
.styledContainer-module_styledContainer__8gqrW.styledContainer-module_noHero__O1M72 {
  padding-top: 102px;
}
.styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundImage__-ib5C {
  background-image: var(--bg-mobile);
}
@media (min-width: 768px) {
  .styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundImage__-ib5C {
    background-image: var(--bg-tablet, var(--bg-mobile));
  }
}
@media (min-width: 1024px) {
  .styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundImage__-ib5C {
    background-image: var(--bg-desktop, var(--bg-tablet, var(--bg-mobile)));
  }
}
.styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundGradient__KZdKb::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background: var(--bg-gradient-mobile);
}
@media (min-width: 768px) {
  .styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundGradient__KZdKb::before {
    background: var(--bg-gradient-tablet, var(--bg-gradient-mobile));
  }
}
@media (min-width: 1024px) {
  .styledContainer-module_styledContainer__8gqrW.styledContainer-module_backgroundGradient__KZdKb::before {
    background: var(--bg-gradient-desktop, var(--bg-gradient-tablet, var(--bg-gradient-mobile)));
  }
}
.iconButton-module_button__V0WrV {
  font-size: 0.875rem;
  font-weight: 700;
  background: #322790;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  letter-spacing: 1px;
  justify-content: center;
  padding-left: 16px;
  padding-right: 24px;
  text-transform: uppercase;
  stroke: #fff;
  text-decoration: none;
}
.w2 .iconButton-module_button__V0WrV {
  background: #0000eb;
  color: #fff;
  stroke: #fff;
}
.iconButton-module_button__V0WrV svg {
  stroke: transparent;
  fill: #fff;
  margin-right: 8px;
  margin-left: 4px;
  height: 24px;
}
.iconButton-module_button__V0WrV img {
  margin-right: 8px;
  margin-left: 4px;
  height: 24px;
  filter: invert(99%) sepia(97%) saturate(19%) hue-rotate(242deg) brightness(103%) contrast(100%);
}
.iconButton-module_button__V0WrV.iconButton-module_genreLink__LIUv5 {
  display: inline-flex;
  border-left: 2px solid;
  border-right: 2px solid;
  border-color: transparent;
}

.iconButton-module_button__V0WrV:hover,
.iconButton-module_button__V0WrV:focus {
  background: transparent;
  border: 2px solid #322790;
  color: #322790;
  stroke: #322790;
}
.w2 .iconButton-module_button__V0WrV:hover,
.w2 .iconButton-module_button__V0WrV:focus {
  border: 2px solid #0000eb;
  color: #0000eb;
  stroke: #0000eb;
}
.iconButton-module_button__V0WrV:hover svg,
.iconButton-module_button__V0WrV:focus svg {
  fill: #322790;
}
.w2 .iconButton-module_button__V0WrV:hover svg,
.w2 .iconButton-module_button__V0WrV:focus svg {
  fill: #0000eb;
}
.iconButton-module_button__V0WrV:hover img,
.iconButton-module_button__V0WrV:focus img {
  filter: invert(10%) sepia(54%) saturate(6542%) hue-rotate(248deg) brightness(91%) contrast(91%);
}
.w2 .iconButton-module_button__V0WrV:hover img,
.w2 .iconButton-module_button__V0WrV:focus img {
  filter: invert(9%) sepia(100%) saturate(7494%) hue-rotate(248deg) brightness(80%) contrast(142%);
}
.checkbox-module_checkboxItem__wtI6h input:focus + label::before, .checkbox-module_toggle-item__aZ1Td input:focus + label::before {
  box-shadow: 0 0 5px rgba(94, 158, 214, 0.75);
  outline: 2px solid rgba(94, 158, 214, 0.75);
  outline-offset: 0.5px;
}

.checkbox-module_checkboxItem__wtI6h, .checkbox-module_toggle-item__aZ1Td {
  position: relative;
}
.checkbox-module_checkboxItem__wtI6h input[type=checkbox], .checkbox-module_toggle-item__aZ1Td input[type=checkbox], .checkbox-module_checkboxItem__wtI6h input[type=radio], .checkbox-module_toggle-item__aZ1Td input[type=radio] {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  opacity: 0.0001;
}
.checkbox-module_checkboxItem__wtI6h label, .checkbox-module_toggle-item__aZ1Td label {
  display: block;
  position: relative;
  padding: 9px 0 9px 30px;
  line-height: 22px;
  cursor: pointer;
}

.checkbox-module_checkboxItem__wtI6h {
  position: relative;
  margin-top: 0;
}
.checkbox-module_checkboxItem__wtI6h:not(:last-child) {
  margin-bottom: 8px;
}
.checkbox-module_checkboxItem__wtI6h .checkbox-module_checkbox__PT13W {
  position: relative;
}
.checkbox-module_checkboxItem__wtI6h .checkbox-module_checkbox__PT13W:checked + label::before {
  content: "";
  background: #006ed7;
  border-color: #006ed7;
}
.w2 .checkbox-module_checkboxItem__wtI6h .checkbox-module_checkbox__PT13W:checked + label::before {
  background: #0000eb;
  border-color: #0000eb;
}
.checkbox-module_checkboxItem__wtI6h .checkbox-module_checkbox__PT13W[aria-invalid=true] + label::before {
  border-color: #eb0a0a;
}
.checkbox-module_checkboxItem__wtI6h label::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  background: #fff;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #736e7d;
}
.checkbox-module_checkboxItem__wtI6h svg {
  position: absolute;
  top: 12px;
  left: 2px;
  width: 16px;
  height: 16px;
  pointer-events: none;
  display: none;
}
.checkbox-module_checkboxItem__wtI6h svg path {
  stroke: #fff;
}
.checkbox-module_checkboxItem__wtI6h .checkbox-module_checkbox__PT13W:checked + label + svg {
  display: block;
}
.checkbox-module_checkboxItem__wtI6h.checkbox-module_invalid__Qu-hh label::before {
  border: 2px solid #eb0a0a;
}

.checkbox-module_toggle-item__aZ1Td {
  position: relative;
}
.checkbox-module_toggle-item__aZ1Td .checkbox-module_toggle__gnK4s:checked + label::before {
  content: "";
  border-color: #006ed7;
  background: #006ed7;
}
.checkbox-module_toggle-item__aZ1Td label {
  padding-left: 50px;
}
.checkbox-module_toggle-item__aZ1Td label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #736e7d;
  display: block;
  width: 40px;
  height: 20px;
  border: 2px solid #736e7d;
  border-radius: 10px;
  transition: 0.3s ease, outline 0s;
}
.invalid-module_invalidFeedback__qQRCG {
  color: #eb0a0a;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;
}
.invalid-module_invalidFeedback__qQRCG.invalid-module_hide__z61nu {
  display: none;
}
.invalid-module_invalidFeedback__qQRCG svg circle {
  fill: #eb0a0a;
}
.fieldset-module_fieldset__Ls-gj {
  padding: 0;
  border: 0;
  margin: 0;
  min-inline-size: auto;
}
.fieldset-module_fieldset__Ls-gj:not(:last-child) {
  margin-bottom: 20px;
}

.fieldset-module_fieldsetLegend__I6907 {
  margin-bottom: 16px;
}
.fieldset-module_fieldsetLegend__I6907.fieldset-module_themeHidden__WA90n {
  display: none;
}
.fieldset-module_fieldsetLegend__I6907.fieldset-module_themeGrey__Wt4jo {
  color: #736e7d;
  font-size: 0.875rem;
  font-weight: 700;
}
.formerrordisplay-module_errorMessage__7Nwck {
  font-size: 0.875rem;
  color: #eb0a0a;
  font-weight: 700;
  margin-bottom: 16px;
}
.radio_radio-item__gQ9Nq input:focus + label::before {
  box-shadow: 0 0 5px rgba(94, 158, 214, 0.75);
  outline: 2px solid rgba(94, 158, 214, 0.75);
  outline-offset: 0.5px;
}

.radio_radio-item__gQ9Nq {
  position: relative;
}
.radio_radio-item__gQ9Nq input[type=checkbox], .radio_radio-item__gQ9Nq input[type=radio] {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  opacity: 0.0001;
}
.radio_radio-item__gQ9Nq label {
  display: block;
  position: relative;
  padding: 9px 0 9px 30px;
  line-height: 22px;
  cursor: pointer;
}

.radio_radio-item__gQ9Nq {
  position: relative;
  margin-top: 0;
}
.radio_radio-item__gQ9Nq:not(:last-child) {
  margin-bottom: 8px;
}
.radio_form-container__euyq3 .radio_radio-item__gQ9Nq {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

.radio_radio-item__gQ9Nq .radio_radio__ezfZM:checked + label::before {
  content: "";
  border-color: #006ed7;
}
.radio_radio-item__gQ9Nq .radio_radio__ezfZM[aria-invalid=true] + label::before {
  border-color: #eb0a0a;
}
.radio_radio-item__gQ9Nq .radio_radio__ezfZM:checked + label::after {
  content: "";
  position: absolute;
  background: #006ed7;
  top: 14px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.radio_radio-item__gQ9Nq .radio_radio__ezfZM:focus + label::before {
  border-radius: 50%;
}
.radio_radio-item__gQ9Nq label::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  background: #fff;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #736e7d;
  border-radius: 50%;
}
.radio_radio-item__gQ9Nq.radio_invalid__dTtow label::before {
  border: 2px solid #eb0a0a;
}

.radio_invalid__dTtow .radio_radio-item__gQ9Nq label::before {
  border: 2px solid #eb0a0a;
}
.responsiveImage-module_picture__A60w- {
  width: 100%;
}
@media (min-width: 768px) {
  .responsiveImage-module_picture__A60w-.responsiveImage-module_mobileOnly__YITtu {
    display: none;
  }
}
.responsiveImage-module_picture__A60w-.responsiveImage-module_tabletOnly__h6a-F {
  display: none;
}
@media (min-width: 768px) {
  .responsiveImage-module_picture__A60w-.responsiveImage-module_tabletOnly__h6a-F {
    display: block;
  }
}
.responsiveImage-module_picture__A60w-.responsiveImage-module_desktopOnly__slBKu {
  display: none;
}
@media (min-width: 1024px) {
  .responsiveImage-module_picture__A60w-.responsiveImage-module_desktopOnly__slBKu {
    display: block;
  }
}
.YoutubeVideo-module_videoWrapper__6Vel5 {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.YoutubeVideo-module_videoWrapper__6Vel5 iframe {
  border-width: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.textLayout-module_largeText__qLIyP p {
  font-size: 1rem;
}

.textLayout-module_centerButton__CLrRv a {
  margin-left: auto;
  margin-right: auto;
}

.textLayout-module_rightButton__Xij9S a {
  margin-left: auto;
}
.navHeader-module_wrapper__-Gd-b {
  height: 56px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  --icon-highlight-background-color: transparent;
  --icon-color: var(--white);
  --panel-margin: 18px;
  display: flex;
}
@media (min-width: 1024px) {
  .navHeader-module_wrapper__-Gd-b {
    height: 72px;
  }
}
@media (min-width: 1024px) {
  .navHeader-module_wrapper__-Gd-b.navHeader-module_searchActive__iUf-S {
    background-color: #000;
    --icon-highlight-background-color: transparent;
    --icon-color: var(--white);
  }
  .w2 .navHeader-module_wrapper__-Gd-b.navHeader-module_searchActive__iUf-S {
    background-color: var(--overlay-contrast-subtle, #ffffff) !important;
    --icon-highlight-background-color: transparent;
    --icon-color: var(--black);
  }
}

@media (min-width: 1024px) {
  .navHeader-module_wrapper__-Gd-b:hover {
    background-color: #000;
    --icon-highlight-background-color: transparent;
    --icon-color: var(--white);
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.navHeader-module_wrapper__-Gd-b.navHeader-module_noHover__nphD5:hover {
  box-shadow: none;
}

@media (min-width: 1024px) {
  .navHeader-module_wrapper__-Gd-b.navHeader-module_keyboardNavActive__NSGHs {
    background-color: #000;
    --icon-highlight-background-color: transparent;
    --icon-color: var(--white);
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.navHeader-module_navContainerWrapper__2l6g- {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 56px;
  max-width: none;
  position: relative;
}
@media (min-width: 1024px) {
  .navHeader-module_navContainerWrapper__2l6g- {
    height: 72px;
  }
}
.navHeader-module_navContainerWrapper__2l6g- .navHeader-module_basicType__8ow3J .navHeader-module_mobileAccWidget__ObFP-.navHeader-module_mobileAccWidgetWithSpacing__GeLXH {
  right: 46px;
}
@media (max-width: 767px) {
  .navHeader-module_navContainerWrapper__2l6g- .navHeader-module_basicType__8ow3J .navHeader-module_mobileAccWidget__ObFP-.navHeader-module_mobileAccWidgetWithSpacing__GeLXH {
    right: 0;
  }
}

.navHeader-module_navContainer__Qi8pA {
  display: flex;
  height: 56px;
  margin: 0 auto;
  width: 100%;
  max-width: 1336px;
  position: relative;
  padding: 0px;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .navHeader-module_navContainer__Qi8pA {
    justify-content: flex-start;
    padding: 0 56px;
    height: 72px;
  }
}

.navHeader-module_navContainer__Qi8pA.navHeader-module_web2PageStyles__sZnJ6 {
  max-width: 1440px;
  padding: 0px 40px;
}
@media (min-width: 1440px) {
  .navHeader-module_navContainer__Qi8pA.navHeader-module_web2PageStyles__sZnJ6 {
    padding: 0px 80px;
  }
}

.navHeader-module_logo__ETNAv {
  display: flex;
  align-items: center;
  height: 100%;
  width: 90px;
}
@media (min-width: 375px) {
  .navHeader-module_logo__ETNAv {
    width: 136px;
  }
}
.navHeader-module_logo__ETNAv svg {
  height: 22px;
  width: 142px;
  fill: #000;
}

.navHeader-module_basicType__8ow3J {
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 540px) {
  .navHeader-module_basicType__8ow3J {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (min-width: 768px) {
  .navHeader-module_basicType__8ow3J {
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media (min-width: 1024px) {
  .navHeader-module_basicType__8ow3J {
    padding-left: 56px;
    padding-right: 56px;
  }
}
.navHeader-module_basicType__8ow3J button {
  order: 2;
}

.navHeader-module_absolute__y1Uth {
  position: absolute;
  z-index: 201;
}
.w2 .navHeader-module_absolute__y1Uth.navHeader-module_mobileSearchActive__cB-Xi {
  position: fixed;
}

.navHeader-module_mainNav__TBk0j {
  visibility: hidden;
  transition: visibility 0.3s;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 202;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j {
    display: flex;
    visibility: visible;
    align-items: center;
    position: relative;
  }
}
.navHeader-module_mainNav__TBk0j.navHeader-module_open__oi7ri {
  visibility: visible;
}
.navHeader-module_mainNav__TBk0j.navHeader-module_open__oi7ri .navHeader-module_background__Vdk0- {
  background-color: rgba(45, 42, 43, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
  visibility: visible;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j.navHeader-module_open__oi7ri .navHeader-module_background__Vdk0- {
    visibility: hidden;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_mainNavBar__2lhig {
  position: absolute;
  top: 0;
  left: -262px;
  width: 262px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: left 0.3s;
  overflow: auto;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_mainNavBar__2lhig.navHeader-module_open__oi7ri {
  left: 0;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15);
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_mainNavBar__2lhig {
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: none;
    flex-direction: row;
    background-color: unset;
    overflow: unset;
    left: 0;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ {
  flex-direction: row;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  justify-content: flex-start;
  background-color: inherit;
  position: static;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ.navHeader-module_right__6qvbT {
  justify-content: flex-end;
  width: 100%;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ.navHeader-module_quickLinks__9azzP {
    display: none;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ.navHeader-module_quickLinks__9azzP .navHeader-module_quickLinkText__jYLY- {
  font-size: 0.875rem;
}
@media (max-width: 1023px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ {
    display: block;
    margin: 0;
    padding: 0;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_navButtonHasMore__ub9Bp {
  color: #000;
  font-weight: 700;
  font-size: 0.875rem;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_navButtonHasMore__ub9Bp {
    color: #fff;
    padding: 0 12px;
    border: 0;
    height: 72px;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_navButtonHasMore__ub9Bp .navHeader-module_searchIcon__e5tnt svg,
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_navButtonHasMore__ub9Bp .navHeader-module_searchClose__fcohq svg {
  display: flex;
  stroke: #fff;
  width: 24px;
  height: 24px;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu {
  display: flex;
  align-items: center;
  list-style-type: none;
  position: relative;
}
.w2 .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_searchActive__iUf-S {
  color: var(--base-contrast, #202020);
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobileDisplay__ZFsiX {
    display: none;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobileHide__Qi7sm {
  display: none;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobileHide__Qi7sm {
    display: flex;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_mobDropdownIcon__ykv1s {
  display: flex;
  align-items: center;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_mobDropdownIcon__ykv1s svg {
  position: absolute;
  right: 15px;
  transform: rotate(270deg);
  transform-origin: 50% 50%;
  stroke: #000;
  padding: 3px;
  width: 24px;
  height: 24px;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_mobDropdownIcon__ykv1s svg {
    display: none;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_underlineButton__fvJWi span {
  position: relative;
  display: inline-block;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_underlineButton__fvJWi span::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  height: 2px;
  background-color: #fff;
  display: block;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navButtonSelfDrop__iMMet {
  color: #000;
  font-weight: 700;
  font-size: 0.75rem;
  height: 56px;
  line-height: 1.15;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid #d4d4d4;
  width: 100%;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navButtonSelfDrop__iMMet {
    color: #fff;
    padding: 0 12px;
    border: 0;
    height: 72px;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_dropNav__IFtHp {
  display: none;
  position: absolute;
  top: 55px;
  padding: 0px;
  font-weight: 700;
  list-style-type: none;
  width: 100%;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_dropNav__IFtHp {
    padding: 0px 12px;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_dropNav__IFtHp .navHeader-module_dropNavLink__lzbL4 {
  display: inline-block;
  padding: 10px 0;
  color: #fff;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_dropNav__IFtHp.navHeader-module_mobOpen__xYpE8 .navHeader-module_dropNavLink__lzbL4 {
  display: block;
  line-height: 56px;
  height: 56px;
  padding: 0 14px;
  border-top: 1px solid #d4d4d4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx {
  display: none;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx {
    background: #fff;
    color: #006ed7;
    padding: 16px 24px;
    font-size: 0.875rem;
    font-weight: 400;
    left: 50%;
    top: 100%;
    transform: translateX(-70%);
    position: absolute;
  }
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx .navHeader-module_navDropUpArrow__lP8px {
    width: 0;
    height: 0;
    top: -12px;
    left: 66%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #fff;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-indent: 0;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx ul li {
  list-style: none;
  width: 200px;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu .navHeader-module_navWhiteDrop__MlDZx ul .navHeader-module_whiteDropNavLink__7ramp {
  display: block;
  font-size: 0.875rem;
  line-height: 22px;
  padding: 0 0 8px;
  text-decoration: none;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 202;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 {
    display: none;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 .navHeader-module_mobDropdownIcon__ykv1s svg {
  position: relative;
  right: auto;
  top: auto;
  transform: rotate(90deg);
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 .navHeader-module_mobDropdownTitle__mdVb- {
  font-size: 1rem;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 .navHeader-module_navButtonSelfDrop__iMMet {
  font-size: 0.875rem;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavItem__t-HFu.navHeader-module_mobOpen__xYpE8 ul .navHeader-module_whiteDropNavLink__7ramp {
  line-height: 56px;
  padding: 0 16px;
  border-top: 1px solid #d4d4d4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  height: 56px;
  white-space: nowrap;
  padding: 0 16px;
  border-top: 1px solid #d4d4d4;
  width: 100%;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_left__V5LNh {
  font-size: 0.875rem;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_left__V5LNh.navHeader-module_underlineButton__fvJWi span::after {
  bottom: -7px;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_linkBody__jdw0n a {
  position: relative;
  color: #000;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_linkBody__jdw0n a {
    color: #fff;
  }
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_linkBody__jdw0n.navHeader-module_underlineButton__fvJWi a {
  position: relative;
}
.navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D.navHeader-module_linkBody__jdw0n.navHeader-module_underlineButton__fvJWi a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  height: 2px;
  background-color: #fff;
  display: block;
}
@media (min-width: 1024px) {
  .navHeader-module_mainNav__TBk0j .navHeader-module_topNav__s8ynQ .navHeader-module_topNavLink__e-02D {
    color: #fff;
    padding: 0 12px;
    height: 72px;
    border: none;
  }
}

.navHeader-module_navSearch__BHaFn {
  position: absolute;
  top: 55px;
  width: 100%;
  padding: 0 56px;
  left: 0px;
}

.navHeader-module_mobileSearchBtn__eeX1k {
  color: #fff;
  height: 56px;
  padding: 0 8px;
  border: 0;
  position: absolute;
  display: block;
  left: 60px;
}
@media (min-width: 1024px) {
  .navHeader-module_mobileSearchBtn__eeX1k {
    display: none;
  }
}
.navHeader-module_mobileSearchBtn__eeX1k svg {
  stroke: #fff;
  width: 24px;
  height: 24px;
  transform: scaleX(-1);
}

.navHeader-module_basicTypeSearchButton__0RfMY {
  color: #fff;
  height: 56px;
  padding: 0 8px;
  border: 0;
  display: block;
}
.navHeader-module_basicTypeSearchButton__0RfMY svg {
  stroke: #fff;
  width: 24px;
  height: 24px;
  transform: scaleX(-1);
}
.navHeader-module_basicTypeSearchButton__0RfMY.navHeader-module_searchButtonSpacing__W2Bp2 {
  margin-right: 70px;
}
@media (min-width: 768px) {
  .navHeader-module_basicTypeSearchButton__0RfMY.navHeader-module_searchButtonSpacing__W2Bp2 {
    margin-right: 144px;
  }
}
@media (min-width: 1024px) {
  .navHeader-module_basicTypeSearchButton__0RfMY.navHeader-module_searchButtonSpacing__W2Bp2 {
    margin-right: 165px;
  }
}
@media (min-width: 1440px) {
  .navHeader-module_basicTypeSearchButton__0RfMY.navHeader-module_searchButtonSpacing__W2Bp2 {
    margin-right: 124px;
  }
}

.navHeader-module_hamburgerMenu__rrQaW {
  height: 56px;
  padding: 10px 18px;
  z-index: 202;
  position: absolute;
  display: block;
  left: 0px;
}
@media (min-width: 1024px) {
  .navHeader-module_hamburgerMenu__rrQaW {
    display: none;
  }
}
.navHeader-module_hamburgerMenu__rrQaW svg {
  width: 30px;
  height: 30px;
  transform: scaleX(1.6);
  stroke: #fff;
  padding: 3px;
}

.navHeader-module_mobileAccWidget__ObFP- {
  padding: 0 16px;
  border: 0;
  display: block;
  position: absolute;
  right: 0px;
}
@media (min-width: 1024px) {
  .navHeader-module_mobileAccWidget__ObFP- {
    display: none;
  }
}
.navHeader-module_mobileAccWidget__ObFP-.navHeader-module_showWidget__3Xagi {
  display: block;
}
@media (min-width: 540px) {
  .navHeader-module_mobileAccWidget__ObFP-.navHeader-module_showWidget__3Xagi {
    margin-right: 12px;
  }
}
@media (min-width: 1024px) {
  .navHeader-module_mobileAccWidget__ObFP-.navHeader-module_showWidget__3Xagi {
    margin-right: 24px;
  }
}

.navHeader-module_mobileSearch__m3LrN {
  display: block;
  width: 100%;
  top: 55px;
  left: 0px;
  padding: 0;
  position: absolute;
  background-color: #000;
}
@media (min-width: 768px) {
  .navHeader-module_mobileSearch__m3LrN {
    padding: 0 16px;
  }
}
.w2 .navHeader-module_mobileSearch__m3LrN {
  background-color: var(--white);
}

.navHeader-module_mobNavLogo__7vyz7 {
  position: relative;
  display: flex;
  padding: 0 16px;
  align-items: center;
  font-weight: 700;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  text-align: left;
  height: 56px;
}
.navHeader-module_mobNavLogo__7vyz7 svg {
  height: 22px;
  width: 75px;
}

.navHeader-module_mobNavClose__l1luc {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 56px;
  padding: 0 15px;
  text-align: right;
  font-weight: 700;
  z-index: 203;
  text-decoration: none;
  color: #000;
}
@media (min-width: 1024px) {
  .navHeader-module_mobNavClose__l1luc {
    display: none;
  }
}
.navHeader-module_mobNavClose__l1luc .navHeader-module_mobNavText__paNbs {
  display: block;
  font-weight: 700;
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-left: -2px;
  letter-spacing: 1px;
  line-height: 1;
}
.navHeader-module_mobNavClose__l1luc svg {
  width: 28px;
  height: 28px;
  stroke: #000;
}

.navHeader-module_skipNav__v4BjI {
  background: #000;
}
.navHeader-module_skipNav__v4BjI .navHeader-module_skiNavContainer__MoDrz {
  margin: 0 auto;
  width: 100%;
  max-width: 1336px;
  position: relative;
  padding: 0 56px;
}

.navHeader-module_skipLinks__AYUTC {
  display: block;
  padding: 8px 0;
  position: absolute;
  left: -9999px;
  top: 0;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}
.navHeader-module_skipLinks__AYUTC:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
  background: #000;
}
.navHeader-module_skipLinks__AYUTC:focus {
  outline: none;
  position: static;
  top: 0;
  left: 0;
}
.navHeader-module_skipLinks__AYUTC:focus span {
  padding: 4px;
  border: 2px dotted #fff;
  display: inline-block;
}

.navHeader-module_showForSr__Rspte {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@media (min-width: 1024px) {
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_navContainer__Qi8pA {
    background-color: var(--base-surface, #f7f7f7);
  }
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_navContainerWrapper__2l6g- {
    background-color: var(--base-surface, #f7f7f7);
  }
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_topNavLink__e-02D,
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_topNavItem__t-HFu,
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_navButtonHasMore__ub9Bp,
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_navButtonSelfDrop__iMMet {
    color: var(--base-contrast, #202020) !important;
  }
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_searchClose__fcohq > svg,
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_searchIcon__e5tnt > svg {
    stroke: var(--base-contrast, #202020) !important;
  }
  .w2 .navHeader-module_searchActive__iUf-S .navHeader-module_logo__ETNAv > img {
    filter: brightness(0) saturate(100%) invert(4%) sepia(20%) saturate(3%) hue-rotate(314deg) brightness(97%) contrast(97%);
  }
}
.w2 .navHeader-module_overlay__jzf2k.navHeader-module_searchActive__iUf-S {
  background-color: var(--black);
  opacity: 0.6;
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 3;
}
.searchbar-module_wrapper__uP9lb {
  width: 100%;
  background-color: var(--search-bar-background-color);
  padding: var(--search-bar-mobile-wrapper-padding);
  padding-top: 34px;
}
@media (min-width: 768px) {
  .searchbar-module_wrapper__uP9lb {
    padding: var(--search-bar-desktop-wrapper-padding);
  }
}

.searchbar-module_inputWrapper__XKP-S {
  position: relative;
}

.searchbar-module_searchInput__IydPq {
  margin: 0;
  margin-bottom: 8px;
  width: 100%;
  outline: 0;
  border: 0;
  border-bottom: 1px solid var(--white);
  background-color: transparent;
  padding: 8px 0;
  font-size: 1rem;
  font-weight: 700;
  color: var(--white);
}
.w2 .searchbar-module_searchInput__IydPq {
  color: var(--base-contrast, #202020);
  border-bottom: 1px solid var(--base-contrast, #202020);
}
@media (min-width: 768px) {
  .searchbar-module_searchInput__IydPq {
    margin-bottom: 24px;
    padding: 12px 0;
    padding-top: 16px;
    font-size: 2.5rem;
  }
  .w2 .searchbar-module_searchInput__IydPq {
    border-bottom: 1px solid var(--base-contrast, #202020);
  }
}
.searchbar-module_searchInput__IydPq::-moz-placeholder {
  color: var(--white);
  opacity: 1;
}
.searchbar-module_searchInput__IydPq::placeholder {
  color: var(--white);
  opacity: 1;
}
.w2 .searchbar-module_searchInput__IydPq::-moz-placeholder {
  color: var(--base-contrast, #202020);
}
.w2 .searchbar-module_searchInput__IydPq::placeholder {
  color: var(--base-contrast, #202020);
}

.searchbar-module_clearButton__IsErj {
  text-transform: lowercase;
  top: 30%;
  right: 0;
  --text-button-color: var(--white);
  position: absolute;
  transform: translateY(-50%);
  font-weight: 300;
  color: var(--white) !important;
}
.w2 .searchbar-module_clearButton__IsErj {
  --text-button-color: var(--base-contrast, #202020);
  color: var(--base-contrast, #202020) !important;
}

.searchbar-module_resultsList__Kgi6Q {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-indent: 0;
}
.searchbar-module_resultsList__Kgi6Q li {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.searchbar-module_resultsList__Kgi6Q li:first-of-type {
  margin-top: 8px;
}
@media (min-width: 1024px) {
  .searchbar-module_resultsList__Kgi6Q li:first-of-type {
    margin-top: 4px;
  }
}
.searchbar-module_resultsList__Kgi6Q a {
  line-height: 1;
  text-decoration: none;
  white-space: normal;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--white);
}
.w2 .searchbar-module_resultsList__Kgi6Q a {
  color: var(--base-contrast, #202020);
}
.searchbar-module_resultsList__Kgi6Q a:hover {
  color: var(--gray-semi-dark);
}
@media (min-width: 1024px) {
  .searchbar-module_resultsList__Kgi6Q a {
    font-size: 1.25rem;
  }
}
.searchbar-module_resultsList__Kgi6Q .searchbar-module_allResultsItem__Xd3Q9 {
  margin-top: 8px;
  padding-bottom: 48px;
}
@media (min-width: 768px) {
  .searchbar-module_resultsList__Kgi6Q .searchbar-module_allResultsItem__Xd3Q9 {
    margin-top: 12px;
  }
}
@media (min-width: 1024px) {
  .searchbar-module_resultsList__Kgi6Q .searchbar-module_allResultsItem__Xd3Q9 {
    margin-top: 0;
  }
}
.searchbar-module_resultsList__Kgi6Q .searchbar-module_allResultsItem__Xd3Q9 > a {
  font-weight: 700;
}
@media (min-width: 1024px) {
  .searchbar-module_resultsList__Kgi6Q .searchbar-module_allResultsItem__Xd3Q9 > a {
    font-size: 1.75rem;
  }
}
.searchbar-module_resultsList__Kgi6Q.searchbar-module_largeResultText__cw5Ur {
  gap: 16px;
}
.searchbar-module_resultsList__Kgi6Q.searchbar-module_largeResultText__cw5Ur a {
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .searchbar-module_resultsList__Kgi6Q.searchbar-module_largeResultText__cw5Ur a {
    font-size: 1.625rem;
  }
}
@media (min-width: 1024px) {
  .searchbar-module_resultsList__Kgi6Q.searchbar-module_largeResultText__cw5Ur a {
    font-size: 1.75rem;
  }
}
.searchbar-module_resultsList__Kgi6Q.searchbar-module_largeResultText__cw5Ur .searchbar-module_allResultsItem__Xd3Q9 {
  margin-top: 0;
}
.modal-module_Modal__FoNxt {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  z-index: 1100;
}
.modal-module_Modal__FoNxt.modal-module_opened__Llqf3 {
  display: block;
}
.modal-module_Modal__FoNxt .modal-module_Overlay__YJm11 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1098;
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  border: 0px;
  padding: 0px;
  z-index: 1099;
}
@media (min-width: 768px) {
  .w2 .modal-module_Modal__FoNxt .modal-module_Container__9yW9B {
    border-radius: var(--rounding-medium, 16px);
  }
}
@media (min-width: 768px) {
  .modal-module_Modal__FoNxt .modal-module_Container__9yW9B {
    height: auto;
  }
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8 {
  position: relative;
  background-color: #322790;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  min-height: 48px;
  line-height: 1.5;
  text-align: center;
  overflow-wrap: break-word;
}
.w2 .modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8 {
  background-color: var(--base-contrast, #202020);
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8.modal-module_large__yVnku {
  padding: 12px 16px;
}
@media (min-width: 768px) {
  .modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8.modal-module_large__yVnku {
    padding: 12px 40px;
  }
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8.modal-module_small__EbQsS {
  padding: 12px 16px;
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8 .modal-module_CloseX__l98EZ {
  position: absolute;
  right: 16px;
  top: 24px;
  transform: translate(0, -50%);
  width: 16px;
  height: 16px;
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8 .modal-module_CloseX__l98EZ::before {
  background: #fff;
  content: "";
  left: 50%;
  position: absolute;
  height: 1px;
  top: 50%;
  width: 16px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_TitleBar__U-BC8 .modal-module_CloseX__l98EZ::after {
  background: #fff;
  content: "";
  left: 50%;
  position: absolute;
  height: 1px;
  top: 50%;
  width: 16px;
  transform: translate(-50%, -50%) rotate(135deg);
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_Content__OlDVz.modal-module_none__R1Sd- {
  padding: 0;
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_Content__OlDVz.modal-module_large__yVnku {
  padding: 24px 16px;
  overflow: auto;
}
@media (min-width: 768px) {
  .modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_Content__OlDVz.modal-module_large__yVnku {
    padding: 32px 40px;
  }
}
.modal-module_Modal__FoNxt .modal-module_Container__9yW9B .modal-module_Content__OlDVz.modal-module_small__EbQsS {
  padding: 16px;
}
.Input-module_inputContainer__uclm- {
  border: 1px solid #736e7d;
  background-color: #fff;
  box-sizing: border-box;
  height: 56px;
  margin-top: 0;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
}
.w2 .Input-module_inputContainer__uclm- {
  border-radius: var(--rounding-medium, 16px);
  border: 1px solid var(--base-border-strong, #b3b3b3);
}
.Input-module_inputContainer__uclm- label {
  color: #736e7d;
  font-size: 0.875rem;
  line-height: 20px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 17px) scale(1);
  margin-left: 14px;
  transition: 0.1s ease-in-out;
  z-index: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 0 28px 0 0;
}
.Input-module_inputContainer__uclm- input,
.Input-module_inputContainer__uclm- textarea {
  border: 0;
  font-family: inherit;
  font-size: 0.875rem;
  height: 100%;
  outline: 0;
  padding: 24px 10px 16px;
  width: 100%;
  z-index: 1;
  position: relative;
  background-color: transparent;
  resize: none;
}
.Input-module_inputContainer__uclm- input:-webkit-autofill,
.Input-module_inputContainer__uclm- textarea:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.Input-module_inputContainer__uclm- textarea {
  height: 120px;
}
.Input-module_inputContainer__uclm-.Input-module_invalid__B0mbJ {
  border: 1px solid #eb0a0a;
}
.Input-module_inputContainer__uclm-.Input-module_active__5HRfD label {
  transform: translate(0, 4px) scale(0.75);
  overflow: visible;
}
.Input-module_inputContainer__uclm-.Input-module_active__5HRfD input,
.Input-module_inputContainer__uclm-.Input-module_active__5HRfD textarea {
  border-left: 4px solid #006ed7;
}
.w2 .Input-module_inputContainer__uclm-.Input-module_active__5HRfD input,
.w2 .Input-module_inputContainer__uclm-.Input-module_active__5HRfD textarea {
  border-left: 4px solid #0000eb;
  border-radius: var(--rounding-medium, 16px);
}
.Input-module_inputContainer__uclm-.Input-module_filled__4tmdv {
  box-shadow: none;
}
.Input-module_inputContainer__uclm-.Input-module_filled__4tmdv label {
  transform: translate(0, 4px) scale(0.75);
}
.Input-module_inputContainer__uclm-.Input-module_filled__4tmdv input,
.Input-module_inputContainer__uclm-.Input-module_filled__4tmdv textarea {
  border-left: 0;
  padding-left: 15px;
}
.Input-module_inputContainer__uclm-.Input-module_textareaContainer__45DPU {
  min-height: 122px;
}
.Input-module_inputContainer__uclm- .Input-module_textareaCount__g1iW- {
  color: #736e7d;
  font-size: 0.875rem;
  margin-top: 8px;
}
.select-module_selectContainer__G1cmO {
  --containerBG: transparent;
  --buttonFG: #000;
  --dropBG: #fff;
  --dropFG: #000;
  --dropHoverBG: transparent;
  --dropSelectedBG: rgba(255, 255, 255, 0.14);
  --dropSelectedFG: #fff;
  --outline: 0px solid transparent;
  --buttonHeight: 54px;
  position: relative;
  background-color: var(--containerBG);
  border: var(--outline);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv {
  --buttonFG: #fff;
  --dropFG: #fff;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurple__UoCo4 {
  --containerBG: #322790;
  --dropBG: #322790;
  --dropHoverBG: rgba(255, 255, 255, 0.07);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplemini__oYDQB {
  width: 100%;
  max-width: 190px;
  --buttonHeight: 40px;
  --containerBG: #322790;
  --dropBG: #322790;
  --dropHoverBG: rgba(255, 255, 255, 0.07);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplemini__oYDQB .select-module_selectOptions__cOCEA {
  max-width: 288px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  width: auto;
  min-width: 100%;
  white-space: nowrap;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplehalf__ekqfV {
  --containerBG: #322790;
  --dropBG: #322790;
  --dropHoverBG: rgba(255, 255, 255, 0.07);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplehalf__ekqfV .select-module_selectOptions__cOCEA {
  max-width: 50%;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplefull__YTre0 {
  --containerBG: #322790;
  --dropBG: #322790;
  --dropHoverBG: rgba(255, 255, 255, 0.07);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplefull__YTre0 .select-module_selectOptions__cOCEA {
  max-width: none;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplefull__YTre0 .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: #00e3b9;
  color: #000;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themePurplefull__YTre0 .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) .select-module_selectOption__FeDyq:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeBlue__rVXEB {
  --containerBG: #006ed7;
  --dropBG: #fff;
  --dropHoverBG: #eaeaea;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeBlueshort__Vu3ao {
  width: 100%;
  max-width: 380px;
  --containerBG: #006ed7;
  --dropBG: #fff;
  --dropHoverBG: #eaeaea;
  --dropFG: #000;
  --dropSelectedBG: #006ed7;
  --dropSelectedFG: #fff;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeWhiteblue__ugxmu {
  width: 100%;
  max-width: 380px;
  --buttonFG: #006ed7;
  --containerBG: #fff;
  --dropBG: #fff;
  --dropHoverBG: #e9eef8;
  --dropFG: #006ed7;
  --dropSelectedBG: #006ed7;
  --dropSelectedFG: #fff;
  --arrowColor: #006ed7;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeWhiteblue__ugxmu.select-module_expanded__9sFXY {
  --containerBG: #006ed7;
  --buttonFG: #fff;
  --arrowColor: #fff;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeOutline__vZ2QK {
  --buttonFG: #000;
  --dropFG: #000;
  --outline: 1px solid #000;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeGrayDark__-7n-t {
  --buttonFG: #000;
  --dropFG: #000;
  --outline: none;
  --containerBG: #d4d4d4;
  --dropBG: #d4d4d4;
  --dropHoverBG: #eaeaea;
  --dropSelectedBG: #eaeaea;
  --dropSelectedFG: #000;
  --arrowColor: #000;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeGrayDark__-7n-t.select-module_expanded__9sFXY {
  --containerBG: #c4c4c4;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeBar__0desv:first-of-type .select-module_selectButton__7-6yT button {
    border-right: 1px solid #fff;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_expanded__9sFXY .select-module_selectButton__7-6yT svg {
  transform: translateY(-50%) rotate(180deg);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectButton__7-6yT {
  width: 100%;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectButton__7-6yT label {
  display: none;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectButton__7-6yT button {
  line-height: var(--buttonHeight);
  font-weight: bold;
  font-size: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 54px 0 16px;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectButton__7-6yT button {
    max-width: 380px;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectButton__7-6yT svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA {
  position: absolute;
  top: 100%;
  display: flex;
  width: 100%;
  max-width: 380px;
  border-top: 1px solid #fff;
  z-index: 1;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA {
    flex-direction: row;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf {
  flex-grow: 1;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf .select-module_selectOption__FeDyq {
  padding: 0 16px;
  line-height: 40px;
  width: 100%;
}
.select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA > .select-module_optionList__6nA8n {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeBar__0desv .select-module_selectOptions__cOCEA > .select-module_optionList__6nA8n {
    flex-direction: row;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c {
  --buttonFG: #000;
  --containerBG: #fff;
  --dropBG: #fff;
  --dropHoverBG: var(--base-surface, #f7f7f7);
  --dropFG: #000;
  --outline: 1px solid var(--base-border-strong, #b3b3b3);
  --dropSelectedBG: var(--base-surface, #f7f7f7);
  --dropSelectedFG: #000;
  --arrowColor: #000;
  border: 1px solid var(--base-border-strong, #b3b3b3);
  border-left: none;
  border-radius: 16px;
  transition: border-bottom-left-radius 0.2s step-end;
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
  border-width: 1px !important;
  background-color: transparent;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
    border-radius: var(--rounding-medium, 16px) 0 0 var(--rounding-medium, 16px);
    border: 1px solid var(--base-border-strong, #b3b3b3);
    border-top: none;
    border-bottom: none;
    transition: border-bottom-left-radius 0.2s step-end;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  border-top: none;
  max-width: 380px;
  border-radius: 0 0 var(--rounding-medium, 16px) var(--rounding-medium, 16px);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: var(--base-surface-strong, #f0f0f0);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectOption__FeDyq:last-of-type {
  padding-bottom: 8px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY {
  border-left: 0;
  border-bottom-left-radius: 0;
  transition: none;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
    border-bottom-left-radius: 0;
    transition: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY {
  border-bottom-right-radius: 0;
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
  border-left: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT {
  height: var(--buttonHeight);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
  border: none;
  color: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT svg path {
  stroke: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  outline: 1px solid var(--base-border-strong, #b3b3b3);
  max-width: none;
  border: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_themePurplemini__oYDQB {
  max-width: 100%;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_themePurplemini__oYDQB {
    max-width: 260px;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeBar__0desv.select-module_themeIfWeb2__tCg-c.select-module_themePurplemini__oYDQB .select-module_selectOptions__cOCEA {
  width: 100%;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 {
  margin-top: 0;
  margin-bottom: 16px;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeGrayDark__-7n-t {
  --buttonFG: #000;
  --dropFG: #000;
  --outline: none;
  --containerBG: #d4d4d4;
  --dropBG: #d4d4d4;
  --dropHoverBG: #eaeaea;
  --dropSelectedBG: #eaeaea;
  --dropSelectedFG: #000;
  --arrowColor: #000;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeGrayDark__-7n-t .select-module_selectButton__7-6yT label {
  color: #000;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeGrayDark__-7n-t.select-module_expanded__9sFXY {
  --containerBG: #c4c4c4;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeOutline__vZ2QK {
  --containerBG: #fff;
  --buttonFG: #000;
  --dropFG: #000;
  --outline: 1px solid #736e7d;
  --dropHoverBG: #e9eef8;
  --dropBG: #fff;
  --dropSelectedBG: #006ed7;
  --dropSelectedFG: #fff;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeOutline__vZ2QK.select-module_invalid__3W6yT {
  --outline: 1px solid #eb0a0a;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeOutline__vZ2QK.select-module_disabled__m-Q1c {
  --outline: 0;
  --containerBG: #f5f5f5;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeOutline__vZ2QK.select-module_disabled__m-Q1c button {
  cursor: default;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_hasValue__o3uxl .select-module_selectButton__7-6yT label, .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_expanded__9sFXY .select-module_selectButton__7-6yT label {
  transform: translateY(4px) scale(0.75);
  line-height: 100%;
  overflow: visible;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_expanded__9sFXY .select-module_selectButton__7-6yT svg {
  transform: translateY(-50%) rotate(180deg);
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7:last-child {
  margin-bottom: 0;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT {
  position: relative;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  line-height: var(--buttonHeight);
  padding: 0 32px 0 16px;
  transform: translateY(0px) scale(1);
  transform-origin: top left;
  transition: 0.1s ease-in-out;
  pointer-events: none;
  color: #736e7d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT button {
  line-height: var(--buttonHeight);
  height: var(--buttonHeight);
  padding: 0 32px 0 12px;
  width: 100%;
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT button span {
  white-space: nowrap;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectButton__7-6yT svg path {
  stroke: #736e7d;
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectOptions__cOCEA {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  max-height: 170px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectOptions__cOCEA {
    overflow: auto;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectOptions__cOCEA .select-module_optionList__6nA8n {
  flex-grow: 1;
  overflow: auto;
}
@media (min-width: 768px) {
  .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectOptions__cOCEA .select-module_optionList__6nA8n {
    flex-grow: 0;
    overflow: visible;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7 .select-module_selectOptions__cOCEA .select-module_selectOption__FeDyq {
  line-height: var(--buttonHeight);
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c {
  --buttonFG: #000;
  --containerBG: #fff;
  --dropBG: #fff;
  --dropHoverBG: var(--base-surface, #f7f7f7);
  --dropFG: #000;
  --outline: 1px solid var(--base-border-strong, #b3b3b3);
  --dropSelectedBG: var(--base-surface, #f7f7f7);
  --dropSelectedFG: #000;
  --arrowColor: #000;
  border: 1px solid var(--base-border-strong, #b3b3b3);
  border-left: none;
  border-radius: 16px;
  transition: border-bottom-left-radius 0.2s step-end;
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
  border-width: 1px !important;
  background-color: transparent;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
    border-radius: var(--rounding-medium, 16px) 0 0 var(--rounding-medium, 16px);
    border: 1px solid var(--base-border-strong, #b3b3b3);
    border-top: none;
    border-bottom: none;
    transition: border-bottom-left-radius 0.2s step-end;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  border-top: none;
  max-width: 380px;
  border-radius: 0 0 var(--rounding-medium, 16px) var(--rounding-medium, 16px);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: var(--base-surface-strong, #f0f0f0);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOption__FeDyq:last-of-type {
  padding-bottom: 8px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY {
  border-left: 0;
  border-bottom-left-radius: 0;
  transition: none;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
    border-bottom-left-radius: 0;
    transition: none;
  }
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c {
    border-left: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY {
  border-bottom-right-radius: 0;
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
  border-left: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT {
  height: var(--buttonHeight);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
  border-right: none;
  border-radius: var(--rounding-medium, 16px);
  color: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  border-top: 1px solid var(--base-border-strong, #b3b3b3);
  max-width: none;
  max-height: 270px;
  clip-path: inset(0 0 0 0 round 0 0 16px 0);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA::-webkit-scrollbar {
  width: 15px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-bottom-right-radius: 32px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: var(--rounding-medium, 16px);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeOutline__vZ2QK.select-module_disabled__m-Q1c {
  outline: 0;
  border: none;
  background-color: #f5f5f5;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeOutline__vZ2QK.select-module_disabled__m-Q1c .select-module_selectButton__7-6yT button {
  border-left: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF {
  --buttonFG: #000;
  --containerBG: #fff;
  --dropBG: #fff;
  --dropHoverBG: var(--base-surface, #f7f7f7);
  --dropFG: var(--base-contrast-subtle, #646464);
  --dropSelectedBG: #fff;
  --dropSelectedFG: #000;
  border: 1px solid var(--base-border-strong, #b3b3b3);
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
  border-radius: 10px;
  transition: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectButton__7-6yT label {
  top: 7px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectButton__7-6yT button {
  padding-top: 7px;
  border: 1px solid var(--base-surface, #f7f7f7);
  border-radius: 10px;
  transition: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectButton__7-6yT button svg {
  height: 8px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectButton__7-6yT button svg path {
  stroke: #000;
  stroke-width: 1px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA {
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
  max-height: 80vh;
  width: 100%;
  border: none;
  border-radius: var(--rounding-large, 24px) var(--rounding-large, 24px) 0 0;
  clip-path: none;
  z-index: 3;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA {
    position: absolute;
    top: 120%;
    left: -1px;
    right: -1px;
    bottom: auto;
    max-height: 470px;
    width: 100%;
    border: 1px solid var(--base-surface, #f7f7f7);
    border-radius: 12px;
    overflow-y: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    -ms-overflow-style: scroll;
    scrollbar-width: auto;
  }
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA::-webkit-scrollbar {
    display: block;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf {
  display: flex;
  flex-direction: column;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: #fff;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq {
  position: relative;
  line-height: 40px;
  margin: 0px 8px;
  border-radius: var(--rounding-xsmall, 4px);
  padding: 0 12px;
  font-weight: 400;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq[aria-selected=true] {
  font-weight: 500;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq[aria-selected=true]::after {
  display: block;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq[aria-selected=true]::before {
  display: block;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq::after {
  display: none;
  content: "";
  width: 1px;
  height: 7px;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
  right: 27px;
  top: 19px;
  transform: rotate(-45deg);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq::before {
  display: none;
  content: "";
  width: 1px;
  height: 14px;
  background-color: #000;
  position: absolute;
  right: 20px;
  top: 13px;
  transform: rotate(45deg);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq:active:hover,
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF .select-module_selectOption__FeDyq:focus-visible {
  background-color: var(--base-surface, #f7f7f7);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF.select-module_expanded__9sFXY {
  transition: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
  border: 1px solid var(--base-surface, #f7f7f7);
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
    border: 1px solid var(--base-surface, #f7f7f7);
    border-bottom-left-radius: 10px;
    transition: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF.select-module_expanded__9sFXY .select-module_overlay__6kRjb {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  opacity: 0.6;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeGrayLightWeb2__-neHF.select-module_expanded__9sFXY .select-module_overlay__6kRjb {
    display: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z {
  --buttonFG: #fff;
  --containerBG: #000;
  --dropBG: #fff;
  --dropHoverBG: #fff;
  --dropFG: #000;
  --dropSelectedBG: var(--base-surface, #f7f7f7);
  --dropSelectedFG: #000;
  border: none;
  border-left: 1px solid var(--base-border-strong, #b3b3b3);
  border-radius: var(--rounding-medium, 16px);
  transition: none;
  background-color: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectButton__7-6yT button {
  border-radius: var(--rounding-medium, 16px);
  transition: none;
  color: #fff;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectButton__7-6yT button {
    border-radius: var(--rounding-medium, 16px);
    transition: none;
    border: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectButton__7-6yT button svg {
  height: 8px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectButton__7-6yT button svg path {
  stroke: #fff;
  stroke-width: 1px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectOptions__cOCEA {
  top: unset;
  bottom: -91vh;
  width: 98vw;
  left: -3vw;
  border: none;
  border-radius: var(--rounding-large, 24px);
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectOptions__cOCEA {
    position: absolute;
    left: -1px;
    right: -1px;
    max-height: 250px;
    width: unset;
    border: 1px solid var(--base-surface, #f7f7f7);
    max-width: 250px;
    border-radius: var(--rounding-small, 8px);
    height: unset !important;
    min-height: 74px;
    overflow: hidden;
    top: 68px;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.2);
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: #fff;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z .select-module_selectOption__FeDyq {
  line-height: 40px;
  margin: 8px 8px;
  border-radius: 8px;
  padding: 0 12px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z.select-module_expanded__9sFXY {
  border-bottom-left-radius: var(--rounding-medium, 16px);
  transition: none;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
  border: none;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeForm__Dpwe7.select-module_themeIfWeb2__tCg-c.select-module_themeBlackWeb2__knO8z.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
    border: none;
    border-bottom-left-radius: var(--rounding-medium, 16px);
    transition: none;
  }
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV {
  display: inline-block;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themePurple__UoCo4 {
  --dropBG: #322790;
  --dropFG: #fff;
  --dropHoverBG: #5b27a1;
  --arrowColor: #000;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectButton__7-6yT label {
  display: none;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectButton__7-6yT span {
  margin-right: 8px;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectButton__7-6yT button {
  line-height: inherit;
  font-weight: bold;
  font-size: inherit;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectButton__7-6yT button svg {
  width: 12px;
  height: 8px;
  padding: 0;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectButton__7-6yT button[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectOptions__cOCEA {
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  z-index: 1;
  padding: 8px 0;
}
.select-module_selectContainer__G1cmO.select-module_typeText__0ireV .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf .select-module_selectOption__FeDyq {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  width: 100%;
  white-space: nowrap;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c {
  --buttonFG: #000;
  --containerBG: #fff;
  --dropBG: #fff;
  --dropHoverBG: var(--base-surface, #f7f7f7);
  --dropFG: #000;
  --outline: 1px solid var(--base-border-strong, #b3b3b3);
  --dropSelectedBG: var(--base-surface, #f7f7f7);
  --dropSelectedFG: #000;
  --arrowColor: #000;
  border: 1px solid var(--base-border-strong, #b3b3b3);
  border-left: none;
  border-radius: 16px;
  transition: border-bottom-left-radius 0.2s step-end;
  background-color: transparent;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
    border-radius: var(--rounding-medium, 16px) 0 0 var(--rounding-medium, 16px);
    border: 1px solid var(--base-border-strong, #b3b3b3);
    border-top: none;
    border-bottom: none;
    transition: border-bottom-left-radius 0.2s step-end;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  border-top: none;
  max-width: 380px;
  border-radius: 0 0 var(--rounding-medium, 16px) var(--rounding-medium, 16px);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA .select-module_selectOptionsGroup__xq-Nf:nth-child(2) {
  background-color: var(--base-surface-strong, #f0f0f0);
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectOption__FeDyq:last-of-type {
  padding-bottom: 8px;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY {
  border-left: 0;
  border-bottom-left-radius: 0;
  transition: none;
}
@media (min-width: 768px) {
  .w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c.select-module_expanded__9sFXY .select-module_selectButton__7-6yT button {
    border-bottom-left-radius: 0;
    transition: none;
  }
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT button {
  border: none;
  border-radius: 0;
  color: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectButton__7-6yT svg path {
  stroke: #000;
}
.w2 .select-module_selectContainer__G1cmO.select-module_typeText__0ireV.select-module_themeIfWeb2__tCg-c .select-module_selectOptions__cOCEA {
  border-top: 1px solid var(--base-border-strong, #b3b3b3);
  border-top-right-radius: var(--rounding-medium, 16px);
  border-top-left-radius: var(--rounding-medium, 16px);
}
.select-module_selectContainer__G1cmO button {
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
  color: var(--buttonFG);
}
.select-module_selectContainer__G1cmO button svg {
  stroke: var(--arrowColor, #fff);
}
.w2 .select-module_selectContainer__G1cmO button {
  color: #fff;
}
.w2 .select-module_selectContainer__G1cmO button svg {
  stroke: #fff;
}
.select-module_selectContainer__G1cmO .select-module_selectOptions__cOCEA {
  transition: visibility 0.2s ease-in-out, height 0.2s ease-in-out;
  visibility: visible;
  height: 0px;
  background-color: var(--dropBG);
  color: var(--dropFG);
  border: var(--outline);
  overflow: hidden;
}
.select-module_selectContainer__G1cmO .select-module_selectOptions__cOCEA[aria-hidden=true] {
  visibility: hidden;
}
.select-module_selectContainer__G1cmO .select-module_selectOptions__cOCEA .select-module_selectOption__FeDyq {
  cursor: pointer;
}
.select-module_selectContainer__G1cmO .select-module_selectOptions__cOCEA .select-module_selectOption__FeDyq:hover {
  background-color: var(--dropHoverBG);
}
.select-module_selectContainer__G1cmO .select-module_selectOptions__cOCEA .select-module_selectOption__FeDyq[aria-selected=true] {
  background-color: var(--dropSelectedBG);
  color: var(--dropSelectedFG);
}
.w2 .select-module_selectContainer__G1cmO {
  background-color: var(--base-contrast);
  border-width: 0px !important;
}

.select-module_disabled__m-Q1c:hover {
  background-color: var(--dropBG) !important;
}

.select-module_iconDropDown__oT99X {
  height: 12px;
  stroke-width: 2px;
}

.select-module_overlay__6kRjb {
  display: none;
}

.select-module_selectPlanHeaderWrapper__tnTDR {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .select-module_selectPlanHeaderWrapper__tnTDR {
    margin-top: 0px;
  }
}

.select-module_planHeaderTitleWrapper__qqTk4 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.select-module_planHeaderTitleWrapper__qqTk4 .select-module_clickableArea__TYkJk {
  display: block;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.select-module_planHeaderTitleWrapper__qqTk4 .select-module_clickableArea__TYkJk svg {
  display: flex;
  stroke: var(--black);
  width: 20px;
  height: 20px;
}
@media (min-width: 768px) {
  .select-module_planHeaderTitleWrapper__qqTk4 .select-module_clickableArea__TYkJk {
    display: none;
  }
}

.select-module_selectPlanHeaderTitleStyles__xkY-b {
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--black);
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  .select-module_selectPlanHeaderTitleStyles__xkY-b {
    display: none;
  }
}
.select-group-module_selectGroup__bH8C- {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}
.select-group-module_selectGroup__bH8C-.select-group-module_themeBlue__YUsPl {
  background-color: #006ed7;
}
.select-group-module_selectGroup__bH8C-.select-group-module_themePurple__61uJn {
  background-color: #322790;
}
.select-group-module_selectGroup__bH8C- .select-group-module_selectContainer__Qf1cy {
  position: initial;
}
.tabs-module_tabsWrapper__0BEvD, .tabs-module_eligibilityTabs__PoMD-, .tabs-module_tabs__qWr6P {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tabs-module_tabsWrapper__0BEvD::-webkit-scrollbar, .tabs-module_eligibilityTabs__PoMD-::-webkit-scrollbar, .tabs-module_tabs__qWr6P::-webkit-scrollbar {
  display: none;
}

.tabs-module_eligibilityTabs__PoMD-, .tabs-module_tabs__qWr6P {
  position: relative;
  width: 100%;
  font-size: 0;
  display: inline-flex;
  position: relative;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  scrollbar-width: none;
  scrollbar-color: #eaeaea transparent;
  border-bottom: 1px solid #eaeaea;
}
.tabs-module_eligibilityTabs__PoMD- button, .tabs-module_tabs__qWr6P button {
  background: transparent;
  border-color: transparent;
}

.tabs-module_tabsWrapper__0BEvD {
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
@media (min-width: 768px) {
  .tabs-module_tabsWrapper__0BEvD {
    width: var(--width);
    margin: 0 auto;
  }
}

.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 19px 0 14px;
  position: relative;
  z-index: 2;
  margin-right: 24px;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik:last-child {
  margin-right: 0;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS {
  pointer-events: none;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS::before {
  opacity: 1;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS:focus::before {
  height: 8px;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik::before {
  background: #006ed7;
  content: "";
  width: 100%;
  height: 4px;
  bottom: -2px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.w2 .tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik::before {
  background: #0000eb;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik:hover::before {
  opacity: 1;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik:focus {
  outline: none;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik:focus::before {
  opacity: 1;
}
.tabs-module_tabs__qWr6P .tabs-module_tabsTitle__n76ik .tabs-module_tabCount__eKAfC {
  font-weight: 400;
}

.tabs-module_eligibilityTabs__PoMD- {
  border: unset;
}
@media (min-width: 540px) {
  .tabs-module_eligibilityTabs__PoMD- {
    justify-content: space-around;
  }
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik {
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.5;
  padding: 19px 0 14px;
  position: relative;
  z-index: 2;
  margin-right: 24px;
  color: #607380;
  width: 100%;
}
@media (min-width: 768px) {
  .tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik {
    font-size: 1.3125rem;
  }
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik:last-child {
  margin-right: 0;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS {
  pointer-events: none;
  color: #000;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS::before {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik.tabs-module_active__mDSCS:focus::before {
  height: 2px;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik::before {
  background: #000;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik:hover {
  color: #0f63ff;
  transition: color 0.5s ease-in-out;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik:hover::before {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik:focus {
  outline: none;
  color: #000;
}
.tabs-module_eligibilityTabs__PoMD- .tabs-module_tabsTitle__n76ik:focus::before {
  opacity: 1;
}

.tabs-module_tabsContent__E-ZPE,
.tabs-module_tabsContentTransitionEffect__5XhUW {
  position: relative;
  height: auto;
  width: 100%;
}
.tabs-module_tabsContent__E-ZPE .tabs-module_tabsPanel__ZN3Z4,
.tabs-module_tabsContentTransitionEffect__5XhUW .tabs-module_tabsPanel__ZN3Z4 {
  height: 0;
  padding-top: 24px;
}

.tabs-module_tabsContent__E-ZPE .tabs-module_tabsPanel__ZN3Z4.tabs-module_active__mDSCS {
  height: auto;
}
.tabs-module_tabsContent__E-ZPE .tabs-module_tabsPanel__ZN3Z4:not(.tabs-module_active__mDSCS) {
  display: none;
}

.tabs-module_tabsContentTransitionEffect__5XhUW .tabs-module_tabsPanel__ZN3Z4.tabs-module_active__mDSCS {
  height: auto;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.tabs-module_tabsContentTransitionEffect__5XhUW .tabs-module_tabsPanel__ZN3Z4:not(.tabs-module_active__mDSCS) {
  opacity: 0;
  padding: 0px;
  visibility: hidden;
}

.tabs-module_fullWidthBorder__0B1Z6 {
  border-bottom: 1px solid #eaeaea;
}
.list-module_list__KiRFc {
  position: relative;
  margin-bottom: var(--list-margin-bottom, 32px);
  padding: 0;
  font-size: 0.75rem;
  list-style-type: disc;
  margin-left: 15px;
}
.list-module_list__KiRFc .list-module_item__yAYyE {
  position: relative;
  margin-bottom: 6px;
  padding-left: 7px;
}
.list-module_list__KiRFc .list-module_item__yAYyE:last-child {
  margin-bottom: 0;
}
.list-module_list__KiRFc .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc {
  display: flex;
}
.list-module_list__KiRFc .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt {
  font-size: 0.75rem;
  margin-right: 3px;
  margin-bottom: 0;
  width: 18px;
  flex: auto 0 0;
  display: none;
  align-items: center;
  height: 14px;
}
.list-module_list__KiRFc .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_itemContent__oa8y1 {
  flex-grow: 1;
  display: flex;
  margin-left: var(--body-margin-left);
  align-items: center;
}
.list-module_list__KiRFc.list-module_customIcon__ByV9g {
  list-style-type: none;
  margin-left: 0;
}
.list-module_list__KiRFc.list-module_customIcon__ByV9g .list-module_item__yAYyE {
  padding-left: 0;
}
.list-module_list__KiRFc.list-module_customIcon__ByV9g .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt {
  display: flex;
  flex-shrink: 0;
}
.list-module_list__KiRFc.list-module_customIcon__ByV9g .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt:before {
  background-image: var(--marker-image);
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(2px);
  content: "";
  left: 0;
  top: var(--icon-top);
  width: var(--icon-width);
  height: var(--icon-height);
  position: absolute;
}
.list-module_list__KiRFc.list-module_large__Q2hBy {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 12px;
  list-style-type: none;
  margin-left: 0;
}
.list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE {
  margin: 0 auto 4px 0;
  width: 100%;
  background-color: #fff;
  padding: 24px 12px 24px 28px;
}
.list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc {
  width: 100%;
  flex-direction: column;
}
.list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt {
  font-size: 1rem;
  height: 24px;
}
@media (min-width: 768px) {
  .list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE {
    min-height: 72px;
    padding: 16px 28px 16px 24px;
  }
  .list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc {
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
  }
  .list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt {
    width: 10%;
    align-self: center;
    flex-shrink: 0;
  }
  .list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE:hover {
    z-index: 2;
  }
}
.list-module_list__KiRFc.list-module_large__Q2hBy.list-module_customIcon__ByV9g {
  list-style-type: none;
}
@media (min-width: 768px) {
  .list-module_list__KiRFc.list-module_large__Q2hBy.list-module_customIcon__ByV9g .list-module_item__yAYyE {
    padding-left: 12px;
  }
}
.list-module_list__KiRFc.list-module_large__Q2hBy.list-module_customIcon__ByV9g .list-module_markerSpace__opTMt:before {
  display: block;
  position: relative;
  height: 100%;
}

ol.list-module_list__KiRFc {
  list-style-type: none;
  margin-left: 0;
}
ol.list-module_list__KiRFc .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt {
  display: flex;
  align-items: center;
}
ol.list-module_list__KiRFc .list-module_item__yAYyE .list-module_itemContentWrapper__NMooc .list-module_markerSpace__opTMt:before {
  display: none;
}
@media (min-width: 768px) {
  ol.list-module_list__KiRFc.list-module_large__Q2hBy .list-module_item__yAYyE {
    padding-left: 12px;
  }
}

.list-module_fullWidth__6XDwN {
  width: 100%;
}
.list-module_fullWidth__6XDwN:hover .list-module_itemContentWrapper__NMooc .list-module_itemContent__oa8y1 a {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  text-decoration-color: inherit;
}
.listenlive-module_listenLive__ZDX5L {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listenlive-module_listenLiveLink__otmCE {
  text-decoration: none;
}

.listenlive-module_listenLiveText__cR1O- {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--blue);
  letter-spacing: 1.7px;
  line-height: 20px;
  letter-spacing: 1.7px;
  line-height: 20px;
  text-transform: uppercase;
}

.listenlive-module_playIcon__88SEM {
  width: 66px;
}
@keyframes audioPlayer-module_slideInRight__CW-Yx {
  from {
    width: 0;
    overflow-x: hidden;
    visibility: hidden;
  }
  to {
    width: 100%;
    visibility: visible;
    overflow-x: visible;
  }
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX {
  width: 100%;
  max-width: 400px;
  height: 60px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX button {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0;
  background: transparent;
  border-color: transparent;
  position: relative;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX button svg {
  width: 28px;
  height: 28px;
  fill: #736e7e;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX button:focus-visible svg {
  fill: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX button:focus-visible::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border-radius: 7px;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX button:hover svg {
  fill: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_timeEllapsed__Lucdd {
  width: 40px;
  font-weight: 400;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_timeRemaining__8XcuH {
  width: 40px;
  color: #736e7e;
  font-weight: 700;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG {
  width: 100%;
  position: relative;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG .audioPlayer-module_progressBar__MOM79 {
  --range-progress-bar-ellapsed: #736e7d;
  --range-progress-bar-remaining: #d4d4d4;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 14px;
  background: transparent;
  z-index: 2;
  position: relative;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #736e7d;
  border-radius: 50%;
  border: none;
  -webkit-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #736e7d;
  border-radius: 50%;
  border: none;
  -moz-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:hover + .audioPlayer-module_progressBar__MOM79 {
  --range-progress-bar-ellapsed: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:hover::-webkit-slider-thumb {
  background-color: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:hover::-moz-range-thumb {
  background-color: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:focus + .audioPlayer-module_progressBar__MOM79, .audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:active + .audioPlayer-module_progressBar__MOM79 {
  --range-progress-bar-ellapsed: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:focus::-webkit-slider-thumb, .audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:active::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  background-color: #fff;
  box-shadow: inset 0 0 0 5px #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:focus::-moz-range-thumb, .audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_progressBarWrapper__0-xtG input[type=range]:active::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background-color: #fff;
  box-shadow: inset 0 0 0 5px #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX .audioPlayer-module_slideAnimation__y5wyP {
  animation: audioPlayer-module_slideInRight__CW-Yx 0.5s ease forwards;
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX.audioPlayer-module_active__zQWfn button svg {
  fill: #0000eb;
}
.audioPlayer-module_audioContainer__PFM-c .audioPlayer-module_controls__QjeeX.audioPlayer-module_active__zQWfn .audioPlayer-module_timeRemaining__8XcuH {
  font-weight: 400;
}
.video-module_videoContainer__dpoF4 {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}
.video-module_videoContainer__dpoF4 video {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I {
  position: absolute;
  bottom: 24px;
  right: 16px;
  z-index: 1;
}
@media (min-width: 768px) {
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I {
    right: 36px;
  }
}
@media (min-width: 1024px) {
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I {
    right: 56px;
  }
}
@media (min-width: 1024px) {
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_controlLeft__YeLbC {
    left: 56px;
  }
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button {
  background: transparent;
  border: 1px solid var(--black);
  border-radius: 40px;
  padding: 0;
  width: 44px;
  height: 44px;
  transition: all 0.2s ease-in-out;
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button svg {
  width: 24px;
  height: 24px;
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button:active {
  background: var(--black);
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button:active svg {
  fill: var(--white);
}
@media (min-width: 1024px) {
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button:hover {
    background: var(--black);
  }
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I button:hover svg {
    fill: var(--white);
  }
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button {
  border: 1px solid var(--white);
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button svg {
  fill: var(--white);
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button:active {
  background: var(--white);
}
.video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button:active svg {
  fill: var(--black);
}
@media (min-width: 1024px) {
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button:hover {
    background: var(--white);
  }
  .video-module_videoContainer__dpoF4 .video-module_videoControls__Ylj1I.video-module_themeDark__TMqE7 button:hover svg {
    fill: var(--black);
  }
}
.showmore-module_dropdownIcon__daXra svg {
  margin-left: 3px;
  width: 13px;
}
.showmore-module_dropdownIcon__daXra path {
  stroke: var(--text-button-color, #000);
  stroke-width: 2px;
}
.w2 .showmore-module_dropdownIcon__daXra path {
  stroke: #0000eb;
}

.showmore-module_contentShown__-WRRw > button .showmore-module_dropdownIcon__daXra svg {
  transform: rotate(180deg);
}

.showmore-module_contentWrapper__0iiIO {
  order: 1;
}

.showmore-module_caret__4V0Vi {
  order: 2;
  border-radius: 0;
}

.showmore-module_contentShowWrapper__5ECQW {
  color: var(--text-button-color, #000);
}
.w2 .showmore-module_contentShowWrapper__5ECQW {
  color: #0000eb;
}

.showmore-module_hidden__-GcEE {
  margin-top: 8px;
}
.showmore-module_hidden__-GcEE:not(.showmore-module_show__le63M) {
  display: none;
}
.socialmediaiconlinks-module_list__a5ogI {
  display: flex;
  padding: 0;
  margin: 0;
}
.socialmediaiconlinks-module_list__a5ogI.socialmediaiconlinks-module_wrapIcons__d3VOG {
  flex-wrap: wrap;
  row-gap: 36px;
}
.socialmediaiconlinks-module_list__a5ogI li {
  width: 24px;
  height: 24px;
  padding: 3px;
  list-style: none;
}
.socialmediaiconlinks-module_list__a5ogI li .socialmediaiconlinks-module_item__OtNr9 {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.socialmediaiconlinks-module_emailIcon__wMP6E {
  width: 14px;
}
.titleAndLink-module_container__2NIXg {
  display: flex;
  justify-content: space-between;
}
.titleAndLink-module_container__2NIXg h3 {
  color: #736e7e;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.43;
}
.w2 .titleAndLink-module_container__2NIXg h3 {
  font-weight: 800;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.43;
}
.titleAndLink-module_container__2NIXg a {
  text-transform: capitalize;
}
.tooltip-module_ttWrapper__TpTMF {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttButton__hFW9q {
  padding: 6px;
  cursor: default;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttButton__hFW9q svg {
  width: 24px;
  height: 24px;
  color: var(--white);
  stroke: var(--gray-dark);
  fill: var(--gray-dark);
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM {
  z-index: 1;
  position: absolute;
  background-color: var(--white);
  text-align: left;
  padding: 16px;
  height: auto;
  width: 160px;
}
@media (min-width: 540px) {
  .tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM {
    width: 216px;
  }
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM,
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM p {
  font-size: 0.875rem;
  line-height: 20px;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_top__6-NhE {
  bottom: 40px;
  left: 50%;
  margin-left: -90px;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_left__LlTnr {
  top: -5px;
  right: 100%;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_right__5urMN {
  top: -5px;
  right: auto;
  left: 100%;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_bottom__Dxa0P {
  top: 100%;
  left: 8px;
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_smallShadow__cVpWz {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}
.tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM.tooltip-module_largeShadow__ajUHo {
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
}
.w2 .tooltip-module_ttWrapper__TpTMF .tooltip-module_ttContent__ufZjM {
  border-radius: 24px;
}
.tooltip-module_ttWrapper__TpTMF.tooltip-module_active__nXk9E svg {
  color: var(--gray-dark);
  stroke: var(--white);
  fill: var(--white);
}
.tooltip-module_ttWrapper__TpTMF.tooltip-module_active__nXk9E .tooltip-module_ttButton__hFW9q {
  text-shadow: 0 0 0.75px var(--black), 0 0 0.75px var(--black);
}
.accordion-module_accordionTitle__Bv-aC {
  margin-bottom: 56px;
}
.accordion-module_accordionTitle__Bv-aC h2 {
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 0.9375rem;
  letter-spacing: 7px;
  line-height: 1.53;
  text-align: center;
  margin-bottom: 8px;
}
.accordion-module_accordionTitle__Bv-aC p {
  display: flex;
  justify-content: center;
}

.accordion-module_collectionTitle__NFF4K {
  margin-bottom: 24px;
}
.accordion-module_collectionTitle__NFF4K h2 {
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 8px;
}

.accordion-module_accordionContainer__Nu-xt {
  display: flex;
  flex-wrap: wrap;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc {
  width: 100%;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 {
  padding: 16px 0;
  border-bottom: 1px solid #d4d4d4;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5:last-of-type {
  border-bottom: none;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionButton__3tJKG {
  background: transparent;
  border: none;
  padding: 16px 0;
  margin: 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 22px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG {
  display: block;
  text-align: right;
  padding-left: 20px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG svg {
  width: 23px;
  height: 16px;
  stroke: #000;
  stroke-width: 2px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionButton__3tJKG .accordion-module_smallIcon__BefmV svg {
  width: 14px;
  height: 14px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_footerVersion__wBNsy {
  padding: 0px 18px;
}
@media (min-width: 768px) {
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_footerVersion__wBNsy {
    padding: 0px;
    cursor: default;
  }
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionContentContainer__TV-Tp {
  padding: 0 16px 0 0;
  line-height: 1.57;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_contentPadding__UUt4m {
  padding: 0px 18px;
}
@media (min-width: 768px) {
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_contentPadding__UUt4m {
    padding: 0px;
  }
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5 .accordion-module_accordionContent__Wz7pg {
  height: 0;
  overflow: hidden;
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5.accordion-module_active__tqeuH:last-of-type {
  border-bottom: 1px solid #d4d4d4;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5.accordion-module_active__tqeuH .accordion-module_accordionButton__3tJKG {
  padding-bottom: 16px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5.accordion-module_active__tqeuH .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG svg {
  transform: rotate(180deg);
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_accordion__dNFI5.accordion-module_active__tqeuH .accordion-module_accordionContent__Wz7pg {
  height: auto;
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP {
  border-bottom: 1px solid #000;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP:last-of-type {
  border-bottom: 1px solid #000;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP:first-of-type {
  border-top: 1px solid #000;
}
@media (min-width: 768px) {
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP {
    border: none;
  }
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP h2 {
    margin-bottom: 12px;
  }
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP.accordion-module_active__tqeuH:last-of-type {
    border: none;
  }
  .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_noBorder__dWMBP.accordion-module_active__tqeuH:first-of-type {
    border: none;
  }
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
}
.w2 .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP {
  border-radius: 16px;
  overflow: hidden;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: #fff;
  padding: 21px 32px 21px 16px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconWithHeadline__yE9ld {
  display: flex;
  align-items: center;
  text-align: left;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconWithHeadline__yE9ld img {
  width: 32px;
  height: 28px;
}
.w2 .accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconWithHeadline__yE9ld img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(382%) hue-rotate(198deg) brightness(100%) contrast(96%);
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconWithHeadline__yE9ld img use {
  stroke: #322790;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconWithHeadline__yE9ld .accordion-module_collectionHeadline__bHhTx {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG {
  display: block;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG svg {
  height: 10px;
  width: 10px;
  stroke: #000;
  stroke-width: 2px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionContent__Wz7pg {
  width: 100%;
  padding-left: 20px;
  height: 0;
  overflow: hidden;
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionContent__Wz7pg p {
  padding-bottom: 5px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionContent__Wz7pg p:last-child {
  padding-bottom: 15px;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP .accordion-module_accordionContent__Wz7pg p a {
  text-decoration: none;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_active__tqeuH .accordion-module_accordionButton__3tJKG {
  border-bottom: none;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_active__tqeuH .accordion-module_accordionButton__3tJKG svg {
  transform: rotate(180deg);
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_active__tqeuH .accordion-module_accordionContent__Wz7pg {
  padding-top: 10px;
  height: auto;
  opacity: 1;
  visibility: visible;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_active__tqeuH .accordion-module_accordionContent__Wz7pg a {
  line-height: 1.75;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_darkTheme__OGGhW {
  background-color: #191919;
  border: 1px solid #606060;
  border-radius: 16px;
  overflow: hidden;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_darkTheme__OGGhW .accordion-module_accordionButton__3tJKG {
  background-color: #191919;
}
.accordion-module_accordionContainer__Nu-xt .accordion-module_accordionItem__kIkmc.accordion-module_collection__WB-kP.accordion-module_darkTheme__OGGhW .accordion-module_accordionButton__3tJKG .accordion-module_iconDropdownWrapper__Ps8MG svg {
  stroke: #fff;
}

.accordion-module_paragraphContainer__zMjwm {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .accordion-module_paragraphContainer__zMjwm {
    grid-template-columns: 1fr 1fr;
  }
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD {
  padding-bottom: 40px;
}
@media (min-width: 1024px) {
  .accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD {
    padding-bottom: 48px;
  }
}
@media (min-width: 1336px) {
  .accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD {
    padding-bottom: 56px;
  }
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD:last-child {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD:last-child:nth-child(odd), .accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD:last-child:nth-child(even), .accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD:nth-last-child(2):nth-child(odd) {
    padding-bottom: 0;
  }
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD .accordion-module_paragraphHeadline__vqC2R {
  margin-bottom: 8px;
  font-size: 1rem;
  line-height: 1.43;
  font-weight: 800;
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD .accordion-module_paragraphDesc__YiUPR {
  color: #736e7d;
  margin-bottom: 8px;
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD .accordion-module_paragraphDesc__YiUPR span {
  font-size: 0.875rem;
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD .accordion-module_paragraphButton__Dz-9u {
  font-size: 0.875rem;
  line-height: 1.57;
  font-weight: 700;
  background: transparent;
  border-color: transparent;
  padding: 0;
  margin: 0;
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD .accordion-module_paragraphButton__Dz-9u svg {
  position: relative;
  transform-origin: center center;
  transform: rotate(0deg);
  top: 1px;
  height: 10px;
  width: 10px;
  margin-left: 4px;
  stroke: #000;
  stroke-width: 2px;
}
.accordion-module_paragraphContainer__zMjwm .accordion-module_paragraphItem__UJfrD.accordion-module_active__tqeuH .accordion-module_paragraphButton__Dz-9u svg {
  transform: rotate(180deg);
}

.accordion-module_darkTheme__OGGhW .accordion-module_accordionContent__Wz7pg,
.accordion-module_darkTheme__OGGhW button {
  background-color: #191919;
}
.accordion-module_darkTheme__OGGhW a,
.accordion-module_darkTheme__OGGhW p,
.accordion-module_darkTheme__OGGhW button {
  color: #eee;
}

.accordion-module_darkHeader__0yGoV h2 {
  color: #eee;
}
.accordion-module_darkHeader__0yGoV p {
  color: #b4b4b4;
}
.appDownloadButtons-module_mainWrapper__zcukx {
  display: flex;
  flex-direction: row;
}

.appDownloadButtons-module_badgesWrapper__80wtY {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
@media (min-width: 768px) {
  .appDownloadButtons-module_badgesWrapper__80wtY {
    flex-direction: row;
  }
}

.appDownloadButtons-module_appStoreBadge__Ofxgy {
  display: flex;
  width: auto;
  height: 40px;
  position: relative;
}
.basicfooter-module_footer__6gyQC {
  padding-bottom: 32px;
}
@media (min-width: 768px) {
  .basicfooter-module_footer__6gyQC {
    padding-bottom: 0px;
  }
}

.basicfooter-module_nav__caImJ {
  display: block;
}
@media (min-width: 768px) {
  .basicfooter-module_nav__caImJ {
    display: flex;
  }
}

.basicfooter-module_list__q8gxh {
  display: grid;
  margin: 0 0 12px;
  padding: 0;
  grid-template-columns: 50% 50%;
  list-style-type: none;
}
@media (min-width: 768px) {
  .basicfooter-module_list__q8gxh {
    grid-template-columns: auto auto auto;
  }
}
.basicfooter-module_list__q8gxh li {
  margin: 12px 32px 12px 0;
  font-size: 0.75rem;
}
.basicfooter-module_list__q8gxh a {
  color: var(--black);
  text-decoration: none;
  white-space: nowrap;
}
.basicfooter-module_list__q8gxh a:hover {
  text-decoration: underline;
}
.w2 .basicfooter-module_list__q8gxh a {
  color: #000;
}
.basicfooter-module_list__q8gxh .basicfooter-module_linkIcon__tmAAW {
  vertical-align: middle;
  display: inline-block;
  width: auto;
  height: 14px;
  margin: 0 4px 0 8px;
}

.basicfooter-module_copyrightWrapper__Vx39O {
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .basicfooter-module_copyrightWrapper__Vx39O {
    justify-content: flex-end;
  }
}
.basicfooter-module_copyrightWrapper__Vx39O .basicfooter-module_attribution__TQ1SP {
  margin-top: 12px;
  font-size: 0.75rem;
}
.fullFooter-module_accordionItemWrapper__CIkiY {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 16px 0px;
  padding: 0px;
}
.fullFooter-module_accordionItemWrapper__CIkiY li {
  list-style: none;
  padding: 2px;
}
.fullFooter-module_accordionItemWrapper__CIkiY a {
  display: block;
  text-decoration: none;
  color: #000;
  line-height: 27px;
  width: -moz-max-content;
  width: max-content;
  font-size: 0.875rem;
  font-weight: 400;
}
.fullFooter-module_accordionItemWrapper__CIkiY a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.w2 .fullFooter-module_accordionItemWrapper__CIkiY a {
  color: #000;
}
@media (min-width: 768px) {
  .fullFooter-module_accordionItemWrapper__CIkiY a {
    line-height: 35px;
  }
}

.fullFooter-module_primaryLinksWrapper__MrJvv {
  display: flex;
  width: 100%;
  flex-direction: column;
  -moz-column-gap: 24px;
       column-gap: 24px;
}
@media (min-width: 768px) {
  .fullFooter-module_primaryLinksWrapper__MrJvv {
    flex-direction: row;
  }
  .fullFooter-module_primaryLinksWrapper__MrJvv svg {
    display: none;
  }
}

.fullFooter-module_accordionItem__WgFXw {
  padding: 0px 18px;
}

.fullFooter-module_mobileStoreBadgesWrapper__38-o0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 18px;
  margin-top: 30px;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .fullFooter-module_mobileStoreBadgesWrapper__38-o0 {
    flex-direction: row;
    padding: 0px;
  }
}

.fullFooter-module_socialMediaItemsWrapper__v1yta {
  display: none;
}
@media (min-width: 768px) {
  .fullFooter-module_socialMediaItemsWrapper__v1yta {
    display: block;
    margin: 8px 0px;
  }
  .fullFooter-module_socialMediaItemsWrapper__v1yta li {
    padding: 0px;
    margin-right: 14px;
  }
  .fullFooter-module_socialMediaItemsWrapper__v1yta svg {
    display: block;
    width: 24px;
    height: 24px;
  }
  .fullFooter-module_socialMediaItemsWrapper__v1yta svg:hover {
    fill: #0000eb;
  }
}

.fullFooter-module_socialMediaItemsWrapperMobile__PLfqv {
  display: block;
  margin-top: 24px;
}
.fullFooter-module_socialMediaItemsWrapperMobile__PLfqv li {
  padding: 0px;
  margin-right: 14px;
}
.fullFooter-module_socialMediaItemsWrapperMobile__PLfqv svg {
  display: block;
  width: 24px;
  height: 24px;
}
.fullFooter-module_socialMediaItemsWrapperMobile__PLfqv svg:hover {
  fill: #0000eb;
}
@media (min-width: 768px) {
  .fullFooter-module_socialMediaItemsWrapperMobile__PLfqv {
    display: none;
  }
}
.simplefooter-module_mainWrapper__gbhCr {
  padding-bottom: 85px;
  position: relative;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr {
    padding-bottom: 24px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh {
  display: flex;
  width: 100%;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh .simplefooter-module_accessLinksLeftBlock__KJ4JJ {
  display: flex;
  align-items: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 20px 20px 10px;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh .simplefooter-module_accessLinksLeftBlock__KJ4JJ {
    border: none;
    margin-right: 10px;
    padding: 0px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh .simplefooter-module_accessLinksLeftBlock__KJ4JJ .simplefooter-module_divider__W7FMa {
  border: 0.5px solid #000;
  height: 8px;
  width: 1px;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh .simplefooter-module_supportLink__BBu42 {
  border-bottom: 1px solid #000;
  padding: 20px 20px 20px 18px;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh .simplefooter-module_supportLink__BBu42 {
    border: none;
    padding: 0px 8px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  padding: 0;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul {
    margin: 0 0 20px 0;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul div {
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  border-bottom: 1px solid #000;
  width: 100%;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul div:first-child {
  border-top: 1px solid #000;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul div:first-child {
    border: 0;
  }
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul div {
    margin: 0 10px;
    width: auto;
    border: 0;
    padding: 20px 0;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li {
  list-style: none;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li a {
  text-decoration: none;
  color: #000;
  line-height: 22px;
  font-size: 0.875rem;
  font-weight: 400;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li a:hover {
  color: #0000eb;
}
.w2 .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li a {
  color: #000;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li:not(:last-child)::after {
  content: "";
  border: 0.5px solid #000;
  height: 8px;
  width: 1px;
  display: inline-block;
  margin: 0 8px;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul {
    justify-content: center;
    flex-direction: row;
  }
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_accessLinks__pivTh ul li a {
    font-weight: 700;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV,
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_appStoreBadges__013un,
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_copyright__YNipP {
  display: flex;
  justify-content: flex-start;
  padding-left: 18px;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV,
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_appStoreBadges__013un,
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_copyright__YNipP {
    justify-content: center;
    padding: 0px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV {
  margin-bottom: 7px;
  order: 2;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV li {
  margin-right: 14px;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV li svg {
  width: 24px;
  height: 24px;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV li svg:hover {
  fill: #0000eb;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV {
    order: 1;
  }
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_socialMediaIcons__IqFiV li {
    margin-right: 20px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_appStoreBadges__013un {
  padding: 12px 0px 12px 18px;
  order: 1;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_appStoreBadges__013un {
    padding: 12px 0px;
    order: 2;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 {
  margin-top: 20px;
  order: 3;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  padding-left: 18px;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul {
    flex-direction: row;
    justify-content: center;
    padding: 0px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li {
  list-style: none;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li {
    margin-bottom: 0px;
  }
  .simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li:not(:last-child):after {
    content: "|";
    font-size: 0.5rem;
    margin-left: 6px;
    margin-right: 6px;
  }
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li a {
  text-decoration: none;
  color: #736e7d;
  white-space: nowrap;
  display: flex;
  font-size: 0.75rem;
  line-height: 1.15;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_secondaryLinks__L7fc6 ul li .simplefooter-module_linkIcon__a7rPW {
  vertical-align: middle;
  display: inline-block;
  width: auto;
  height: 14px;
  margin: 0 4px 0 8px;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_copyright__YNipP {
  color: #736e7d;
  order: 4;
}
.simplefooter-module_mainWrapper__gbhCr .simplefooter-module_simpleFooterWrapper__zIS8g .simplefooter-module_copyright__YNipP p {
  font-size: 0.75rem;
}
.pagination-module_paginationWrapper__-SZwz {
  display: flex;
}
.pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R {
  display: none;
}
@media (min-width: 768px) {
  .pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 0px 16px;
  }
}
.pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R .pagination-module_paginationButtonStyles__ix5Kc {
  border-radius: 50%;
}
.pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R .pagination-module_paginationButtonStyles__ix5Kc.pagination-module_activeButtonStyles__fEI4c {
  background-color: #000;
  color: #fff;
}
.pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R .pagination-module_paginationButtonStyles__ix5Kc.pagination-module_activeButtonStyles__fEI4c:hover {
  background-color: #000;
}
.pagination-module_paginationWrapper__-SZwz .pagination-module_paginationButtonsWrapper__Fql-R .pagination-module_paginationButtonStyles__ix5Kc:hover {
  background-color: #e4e9ec;
}

.pagination-module_mobilePagePositionIndicatorStyles__JHRAH {
  display: flex;
  align-items: center;
  margin: 0px 32px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .pagination-module_mobilePagePositionIndicatorStyles__JHRAH {
    display: none;
  }
}

.pagination-module_rightArrowNoHoverStyles__oyFl6,
.pagination-module_leftArrowNoHoverStyles__z3Dld {
  display: flex;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6 svg,
.pagination-module_leftArrowNoHoverStyles__z3Dld svg {
  width: 20px;
  height: 20px;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6 svg path,
.pagination-module_leftArrowNoHoverStyles__z3Dld svg path {
  stroke: #000;
  stroke-width: 3;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6:active,
.pagination-module_leftArrowNoHoverStyles__z3Dld:active {
  background-color: #000;
  border: 1px solid transparent;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6:active svg path,
.pagination-module_leftArrowNoHoverStyles__z3Dld:active svg path {
  stroke: #fff;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6:disabled,
.pagination-module_leftArrowNoHoverStyles__z3Dld:disabled {
  border: 1px solid #d0d8dd;
}
.pagination-module_rightArrowNoHoverStyles__oyFl6:disabled svg path,
.pagination-module_leftArrowNoHoverStyles__z3Dld:disabled svg path {
  stroke: #d0d8dd;
}

.pagination-module_leftArrowButtonStyles__H0a0N,
.pagination-module_rightArrowButtonStyles__A4xQb {
  display: flex;
}
.pagination-module_leftArrowButtonStyles__H0a0N svg,
.pagination-module_rightArrowButtonStyles__A4xQb svg {
  width: 20px;
  height: 20px;
}
.pagination-module_leftArrowButtonStyles__H0a0N svg path,
.pagination-module_rightArrowButtonStyles__A4xQb svg path {
  stroke: #000;
  stroke-width: 3;
}
.pagination-module_leftArrowButtonStyles__H0a0N:hover,
.pagination-module_rightArrowButtonStyles__A4xQb:hover {
  background-color: #000;
  border: 1px solid transparent;
}
.pagination-module_leftArrowButtonStyles__H0a0N:hover svg path,
.pagination-module_rightArrowButtonStyles__A4xQb:hover svg path {
  stroke: #fff;
}
.pagination-module_leftArrowButtonStyles__H0a0N:disabled,
.pagination-module_rightArrowButtonStyles__A4xQb:disabled {
  border: 1px solid #d0d8dd;
}
.pagination-module_leftArrowButtonStyles__H0a0N:disabled svg path,
.pagination-module_rightArrowButtonStyles__A4xQb:disabled svg path {
  stroke: #d0d8dd;
}

.pagination-module_leftArrowButtonStyles__H0a0N,
.pagination-module_leftArrowNoHoverStyles__z3Dld {
  transform: rotate(180deg);
}

.pagination-module_ellipsisStyles__zDTkt {
  display: flex;
  align-items: center;
}
.pagination-module_ellipsisStyles__zDTkt p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.45;
  letter-spacing: 0.6px;
}
@keyframes contentSamplingPlayer-module_slideInRight__-OeQv {
  from {
    width: 0;
    overflow-x: hidden;
    visibility: hidden;
  }
  to {
    width: 100%;
    visibility: visible;
    overflow-x: visible;
  }
}
.contentSamplingPlayer-module_audioContainer__Q4M8J {
  padding-left: 20px;
  padding-right: 20px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 {
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 button {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0;
  background: transparent;
  border-color: transparent;
  position: relative;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 button svg {
  width: 20px;
  height: 20px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_timeDisplayWrapper__4Ngcu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_timeEllapsed__4wXb2 {
  width: 40px;
  font-weight: 400;
  color: #736e7d;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_timeRemaining__RSEZT {
  width: 40px;
  color: #736e7e;
  font-weight: 700;
  text-align: flex-end;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_progressBarWrapper__hilK4 {
  width: 100%;
  position: relative;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_progressBarWrapper__hilK4 .contentSamplingPlayer-module_progressBar__8jL9A {
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border-radius: 15px;
  --range-progress-bar-ellapsed: #d4d4d4;
  --range-progress-bar-remaining: #736e7d;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_progressBarWrapper__hilK4 input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 24px;
  background: transparent;
  z-index: 2;
  position: relative;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_progressBarWrapper__hilK4 input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  -webkit-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_progressBarWrapper__hilK4 input[type=range]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  -moz-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightProgressBarWrapper__7nRZy {
  width: 100%;
  position: relative;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightProgressBarWrapper__7nRZy .contentSamplingPlayer-module_progressBar__8jL9A {
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border-radius: 15px;
  --range-progress-bar-ellapsed: #736e7d;
  --range-progress-bar-remaining: #d4d4d4;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightProgressBarWrapper__7nRZy input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 24px;
  background: transparent;
  z-index: 2;
  position: relative;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightProgressBarWrapper__7nRZy input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #736e7d;
  border-radius: 50%;
  border: none;
  -webkit-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightProgressBarWrapper__7nRZy input[type=range]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #736e7d;
  border-radius: 50%;
  border: none;
  -moz-transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  transition: width 0.2s ease, height 0.2s ease, transform 0.2s ease;
  margin-top: 2px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_slideAnimation__nt9wN {
  animation: contentSamplingPlayer-module_slideInRight__-OeQv 0.5s ease forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  padding: 24px 0px 8px 0px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 68px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_playButton__wEN-J {
  width: 68px;
  height: 68px;
  background-color: #fff;
  border-color: transparent;
  margin: 0px 48px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_playButton__wEN-J svg {
  width: 28px;
  height: 28px;
  fill: #000;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_playButton__wEN-J:hover {
  opacity: 50%;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_pauseButton__jBofO {
  width: 68px;
  height: 68px;
  background-color: #736e7e;
  border-color: transparent;
  margin: 0px 48px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_pauseButton__jBofO svg {
  width: 28px;
  height: 28px;
  fill: #fff;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_pauseButton__jBofO:hover {
  opacity: 50%;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_replayButton__Rvt3b {
  margin: 0px 57px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_replayButton__Rvt3b svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_controlsWrapperStyles__gr41E .contentSamplingPlayer-module_transcriptIcon__HMZYG {
  position: absolute;
  right: 0px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 68px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_playButton__wEN-J {
  width: 68px;
  height: 68px;
  background-color: #000;
  border-color: transparent;
  margin: 0px 48px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_playButton__wEN-J svg {
  width: 28px;
  height: 28px;
  fill: #fff;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_playButton__wEN-J:hover {
  opacity: 50%;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_pauseButton__jBofO {
  width: 68px;
  height: 68px;
  background-color: #736e7e;
  border-color: transparent;
  margin: 0px 48px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_pauseButton__jBofO svg {
  width: 28px;
  height: 28px;
  fill: #fff;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_pauseButton__jBofO:hover {
  opacity: 50%;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_replayButton__Rvt3b {
  margin: 0px 57px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_replayButton__Rvt3b svg {
  width: 24px;
  height: 24px;
  fill: #000;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277 .contentSamplingPlayer-module_lightThemePlayerControls__nTnIA .contentSamplingPlayer-module_transcriptIcon__HMZYG {
  position: absolute;
  right: 0px;
}
.contentSamplingPlayer-module_audioContainer__Q4M8J .contentSamplingPlayer-module_controls__dH277.contentSamplingPlayer-module_active__giZE7 .contentSamplingPlayer-module_timeRemaining__RSEZT {
  font-weight: 400;
}
@media (min-width: 1024px) {
  .contentSamplingPlayer-module_audioContainer__Q4M8J {
    padding-left: 0px;
    padding-right: 0px;
  }
}